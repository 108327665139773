import { NavigateOptions, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useParamsHook from './GetParamsHook'

/*
    This hook is useful if you want data backed by the server,
    but you also want the ability to manipulate the data locally.
    Typically for something the user will eventually save to the server.
*/
export default function useNav(): (route: string, options?: NavigateOptions) => void {
    let navigate = useNavigate()
    let location = useLocation()
    return (path, options) => {
        navigate(path, options)
    }
}

export function useCustomerNavigate(): (path: string) => void {
    let navigate = useNavigate()
    let customerID = useParamsHook()?.params['customerId'] as string
    return (path: string) => {
        if (path.startsWith('/')) {
            navigate(path)
        } else {
            navigate('/' + customerID + '/' + path)
        }
    }
}

export function useNavigateHome(): () => void {
    let navigate = useCustomerNavigate()
    return () => {
        navigate('')
    }
}

export function useGoToDetail(): (projectID: string) => void {
    let navigate = useCustomerNavigate()
    return (projectID) => {
        navigate('project/' + projectID)
    }
}

export function useNavigateOptions(): { navigateUser: (pathId: string) => void; navigateHome: () => void; navigateDetail: (projectId: string) => void } {
    let navigateHome = useNavigateHome()
    let goToDetail = useGoToDetail()
    let navigateUser = useCustomerNavigate()

    return { navigateUser: navigateUser, navigateHome: navigateHome, navigateDetail: goToDetail }
}
