import { ErrorBase } from './error'
import axios from 'axios'
import { AxiosError } from 'axios'

export function errorToErrorBase(error: Error): ErrorBase {
    if (axios.isAxiosError(error)) {
        let axiosError: AxiosError = error
        if (axiosError.response?.status === 401) {
            return new ErrorBase({ name: 'LOGGED_OUT', message: 'You have been logged out', cause: error })
        } else {
            return new ErrorBase({ name: 'SERVER_ERROR', message: 'The server is down we are working on it', cause: error })
        }
    } else if (error instanceof ReferenceError) {
        return new ErrorBase({ name: 'REFERENCE_ERROR', message: 'An unkown error occurred.', cause: error })
    }

    return new ErrorBase({ name: 'UNKOWN_ERROR', message: 'An unkown error occurred.', cause: error })
}
