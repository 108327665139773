enum JobStatus {
    InProgress = 'inProgress',
    Completed = 'completed',
    Upcoming = 'upcoming'
}

export function JobStatusColor(status: JobStatus, unscheduled: Boolean): string {
    let color = 'bg-green-200'

    if (unscheduled) {
        return 'bg-yellow-200'
    } else if (status == JobStatus.Upcoming) {
        return 'bg-blue-200'
    } else if (status == JobStatus.InProgress) {
        return 'bg-orange-200'
    }

    return color
}

export default JobStatus
