// App.tsx or any other component
import React, { useMemo } from 'react'
import ExcelStyleTable from '../../components/classicTable/ClassicTable'
import useCacheContext from '../../hooks/CacheHook'
import { BoardItem, BoardItemColor, Invoice } from '../../models/Invoice'
import { useCurrentCustomer } from '../../hooks/CurrentCustomerHook'
import useNav from '../../hooks/NavigationHook'
import JobStatus, { JobStatusColor } from '../../models/JobStatus'
import MapKeys from '../../components/mapKey/MapKey'
import { ConvertUnixToReadableDate } from '../../utility/dates'

function isBoardItem(obj: any): obj is BoardItem {
    // perform checks to determine if obj is a BoardItem
    return obj && typeof obj.name !== 'undefined' // example check
}

const MakeReadyPage = () => {
    let [rawJobs, error, isLoading] = useCacheContext<Invoice[]>('/v1/invoices', (val) => {
        return val.invoices
    })
    let [mappings, mapError, isLoadingMapping, reloadMappings] = useCacheContext<string[]>(
        '/v1/boardMappings',
        (val) => {
            return val.mappings
        },
        [],
        true,
        true
    )
    let nav = useNav()
    let customer = useCurrentCustomer()

    const boardColumns = useMemo(() => {
        let boardItemNames: string[] = []

        mappings?.forEach((item) => {
            boardItemNames.push(item)
        })

        boardItemNames = ['Address'].concat(boardItemNames).concat('Detail Link')

        return boardItemNames
    }, [mappings])

    const rows = useMemo(() => {
        let rows: { [key: string]: BoardItem | string | null }[] = []
        rawJobs?.forEach((item) => {
            var row: { [key: string]: BoardItem | string | null } = {}

            boardColumns.forEach((item) => {
                row[item] = null
            })

            if (item.boardItems != null) {
                item.boardItems.forEach((boardItem) => {
                    row[boardItem.name] = boardItem
                })
            }
            row['Address'] = item.address.street
            row['Detail Link'] = '/' + customer?.id + '/' + 'project' + '/' + item.id
            rows.push(row)
        })

        return rows.sort((a, b) => (a['Address'] as string).localeCompare(b['Address'] as string))
    }, [rawJobs])

    const columns = boardColumns
    const data = rows

    return (
        <div className="h-80v">
            <div className="w-full flex flex-col items-center font-semibold">
                <h1 className="text-2xl p-5 text-center">Make Ready Board</h1>
            </div>
            <div className="w-full flex flex-row justify-center items-center">
                {
                    <MapKeys
                        items={[
                            { name: 'Unscheduled', color: JobStatusColor(JobStatus.Upcoming, true) },
                            { name: 'Upcoming', color: JobStatusColor(JobStatus.Upcoming, false) },
                            { name: 'In Progress', color: JobStatusColor(JobStatus.InProgress, false) },
                            { name: 'Complete', color: JobStatusColor(JobStatus.Completed, false) }
                        ]}
                    />
                }
            </div>
            <ExcelStyleTable
                columns={columns}
                data={data}
                customRender={(key, value) => {
                    if (key == 'Detail Link' && value != '') {
                        return (
                            <button
                                className="w-full text-blue-600"
                                onClick={() => {
                                    nav(value)
                                }}
                            >
                                Details
                            </button>
                        )
                    }
                    if (value == null) {
                        return (
                            <div className="w-full px-6 py-4 bg-green-200">
                                <p>X</p>
                            </div>
                        )
                    }
                    if (isBoardItem(value)) {
                        let boardItem = value as BoardItem

                        return (
                            <div className={'w-full px-6 py-4 ' + BoardItemColor(boardItem)}>
                                <p>{ConvertUnixToReadableDate(boardItem.date)}</p>
                            </div>
                        )
                    }
                    return (
                        <div>
                            <p>{value}</p>
                        </div>
                    )
                }}
            />
        </div>
    )
}

export default MakeReadyPage
