import { useContext, useEffect, useState } from 'react'
import { Plus } from '../../components/icons/Plus'
import InfoBubble from '../../components/infoBubble/InfoBubble'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
import useNav from '../../hooks/NavigationHook'
import StandardButton from '../../components/button/StandardButton'
import { MapRule, InsertMap, UpdateMap } from '../../api/MakeReadyMappings'
import { RequestClose } from '../../contexts/ModalContext'
import useParamsHook from '../../hooks/GetParamsHook'
import useCacheContext from '../../hooks/CacheHook'
import TableRow from '../../components/Row/TableRow'
import useLocalDataContext from '../../hooks/LocalSettingHook'
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { SortableItem } from '../../components/SortableItem'
import axios from 'axios'
//...

function EditMappingOrderPage() {
    let close = useContext(RequestClose)
    let params = useParamsHook()

    let mapId = params?.params.mapId
    let [mappings, setData, post, error, isLoading] = useLocalDataContext<MapRule[]>(
        '/v1/admin/boardMappings',
        async (data) => {
            let order = data.map((item) => {
                return item.id
            })
            await axios.post('/v1/admin/boardMappings/reorder', {
                mappingIds: order
            })
        },
        (val) => {
            return val.mappings
        },
        []
    )
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    function handleDragEnd(event: DragEndEvent) {
        const { active, over } = event

        if (active.id !== over?.id) {
            setData((items) => {
                if (items == null) {
                    return null
                }
                console.log('Active ' + active.id)
                console.log('over ' + over?.id)
                // return items
                const oldIndex = items.findIndex((item) => {
                    return item.id == active.id
                })
                const newIndex = items.findIndex((item) => {
                    return item.id == over?.id
                })
                return arrayMove(items, oldIndex, newIndex)
            })
        }
    }

    return (
        <div className="w-full flex flex-col items-center">
            <ModalCompatibleTitle title="Sort Mappings" />
            <p className="p-4">Drag and drop to reorder your mappings below. The items will appear in order from left to right on the make ready board.</p>
            {mappings != null && (
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                    <SortableContext items={mappings} strategy={verticalListSortingStrategy}>
                        <div className="w-full">
                            {mappings.map((item) => {
                                console.log(item.id)
                                return (
                                    <SortableItem key={item.id} id={item.id} className="w-full">
                                        <TableRow onClick={() => {}}>{item.newValue}</TableRow>
                                    </SortableItem>
                                )
                            })}
                        </div>
                    </SortableContext>
                    <div></div>
                    <StandardButton
                        text="Save"
                        isLoading={isLoading}
                        onClick={async () => {
                            // setIsLoading(true)
                            try {
                                await post()
                                close()
                            } catch (e: any) {
                                console.log('error')
                                // setIsLoading(false)
                            }
                        }}
                    />
                </DndContext>
            )}
        </div>
    )
}

export default EditMappingOrderPage
