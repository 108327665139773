// /v1/admin/customer/preferences

import axios from 'axios'
import { CustomerPreferences } from '../models/CustomerPreferences'

/*
type GetCustomerPreferencesResponse struct {
	Preferences *models.CustomerPreferences `json:"preferences"`
}

*/

interface GetCustomerPreferencesResponse {
    preferences: CustomerPreferences
}

export async function GetCustomerPreferences(): Promise<GetCustomerPreferencesResponse> {
    return (await axios.get('/v1/admin/customer/preferences', {})).data as GetCustomerPreferencesResponse
}

export async function PutCustomerPreferences(prefs: CustomerPreferences): Promise<boolean> {
    let response = await axios.put('/v1/admin/customer/preferences', { preferences: prefs })
    return response.status === 200
}
