import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import useOnOutsideClick from '../../hooks/useOnOutsideClick'
import { useLocation, useNavigate } from 'react-router-dom'
import { InModal, TitleGetContext, TitleSetContext } from '../../contexts/ModalContext'

interface ModalProps {
    title: string
}

const ModalCompatibleTitle: React.FC<ModalProps> = ({ title }) => {
    const setModalTitle = useContext(TitleSetContext)
    const inModal = useContext(InModal)
    useEffect(() => {
        if (setModalTitle != null) {
            setModalTitle(title)
        }
    }, [title])

    if (inModal) {
        return <div></div>
    }

    return <h1 className="">{title}</h1>
}

export default ModalCompatibleTitle
