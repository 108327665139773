import { ReactNode, createContext, useEffect, useState } from 'react'

const IsEmployeeProvider = createContext(false)
const UpdateEmployeeStatus = createContext(() => {})

interface AuthContextProps {
    children: ReactNode
}
const AuthContext: React.FC<AuthContextProps> = ({ children }) => {
    let [isEmployee, setIsEmployee] = useState(false)

    let updateEmployeeStatus = () => {
        let sessionData = localStorage.getItem('session')
        if (sessionData != null) {
            isEmployee = JSON.parse(sessionData)['isEmployee'] ?? false
        } else {
            isEmployee = false
        }
        setIsEmployee(isEmployee)
    }

    useEffect(() => {
        updateEmployeeStatus()
    }, [])

    return (
        <UpdateEmployeeStatus.Provider
            value={() => {
                updateEmployeeStatus()
            }}
        >
            <IsEmployeeProvider.Provider value={isEmployee}>{children}</IsEmployeeProvider.Provider>
        </UpdateEmployeeStatus.Provider>
    )
}

export { IsEmployeeProvider, AuthContext, UpdateEmployeeStatus }
