import { useNavigate, useParams } from 'react-router-dom'
import LoggedOutComponent from '../LoggedOutComponent'
import useNav from '../../hooks/NavigationHook'
import { useEffect } from 'react'
import axios from 'axios'

type UnsubscribeParam = {
    unsubscribeCode: string
}

function UnsubscribeFromEmail() {
    let navigate = useNav()
    const { unsubscribeCode } = useParams<keyof UnsubscribeParam>() as UnsubscribeParam

    useEffect(() => {
        let unsub = async () => {
            try {
                await axios.post('/v1/unsubscribe/email', {
                    code: unsubscribeCode
                })
            } catch (error) {}
        }
        unsub()
    }, [])

    return (
        <LoggedOutComponent>
            <h1 className="text-text text-2xl">Successfully Unsubscribed</h1>
            <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="button"
                onClick={() => {
                    navigate('/')
                }}
            >
                Back Home
            </button>
        </LoggedOutComponent>
    )
}

export default UnsubscribeFromEmail
