import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import CustomerPortalRoutes from './CustomerPortalRoutes'
import ScrollToTop from './components/scrollToTop/ScrollsToTop'
import { AppDataContext } from './contexts/AppContext'
import { NavigationContext } from './contexts/NavigationContext'
import { AuthContext } from './contexts/AuthContext'
import { AxiosSessionContext } from './contexts/AxiosSessionContext'
import { CustomerListContext } from './contexts/CustomerContext'
import { DBProvider } from './contexts/DBProvider'
import { IndexedDBProvider } from './contexts/IndexDBContext'

const RootComponent = () => {
    return (
        <Router>
            <IndexedDBProvider>
                <AuthContext>
                    <CustomerListContext>
                        <NavigationContext>
                            <AppDataContext>
                                <DBProvider>
                                    <AxiosSessionContext>
                                        <ScrollToTop />
                                        <CustomerPortalRoutes />
                                    </AxiosSessionContext>
                                </DBProvider>
                            </AppDataContext>
                        </NavigationContext>
                    </CustomerListContext>
                </AuthContext>
            </IndexedDBProvider>
        </Router>
    )
}

export default RootComponent
