import React, { useState, useRef, useEffect, useCallback } from 'react'

interface PhoneNumberInputProps {
    onChange?: (value: string) => void
    isValid?: (valid: boolean) => void
    onSubmit?: () => void
}

function convertToE164Number(rawNumber: string): string {
    // Remove non-digit characters from the input string
    const digitsOnly = rawNumber.replace(/\D/g, '')

    // Check if the resulting string is numeric and not empty
    if (/^\d+$/.test(digitsOnly)) {
        // Hardcode the country code for the US (+1)
        return `+1${digitsOnly}`
    }

    return ''
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({ onChange, isValid, onSubmit }) => {
    const [digits, setDigits] = useState<string[]>(Array(10).fill(''))
    const inputRefs = useRef<(HTMLInputElement | null)[]>([])

    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 10)
        inputRefs.current[0]?.focus()
    }, [])

    const updateDigits = (newDigits: string[]) => {
        setDigits(newDigits)
        const phoneNumber = newDigits.join('')
        onChange?.(convertToE164Number(phoneNumber))
        isValid?.(phoneNumber.length === 10)
    }

    const focusNextEmptyInput = () => {
        const nextEmptyIndex = digits.findIndex((digit, index) => digit === '')
        if (nextEmptyIndex !== -1) {
            inputRefs.current[nextEmptyIndex]?.focus()
        } else if (digits.every((digit) => digit !== '')) {
            inputRefs.current[9]?.focus()
        }
    }

    const handleClick = (index: number) => {
        focusNextEmptyInput()
    }

    const handleKeyPress = (event: any): void => {
        if (event.key === 'Enter' && digits.join('').length == 10) {
            onSubmit?.()
        }
    }

    const handleBackspace = (index: number) => {
        if (index > 0 && digits[index] === '') {
            inputRefs.current[index - 1]?.focus()
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = e.target.value
        const newDigits = [...digits]

        if (/^\d*$/.test(value)) {
            newDigits[index] = value
            updateDigits(newDigits)

            if (value && index < 9) {
                inputRefs.current[index + 1]?.focus()
            } else if (!value) {
                handleBackspace(index)
            }
        }
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace') {
            handleBackspace(index)
        }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault()
        const pasteData = e.clipboardData.getData('text').slice(0, 10).split('')

        updateDigits(pasteData)
        const nextIndexToFocus = pasteData.length < 10 ? pasteData.length : 9
        inputRefs.current[nextIndexToFocus]?.focus()
    }

    return (
        <div className="flex justify-center items-center space-x-2">
            {digits.map((digit, index) => (
                <>
                    <input
                        key={index}
                        ref={(el) => (inputRefs.current[index] = el)}
                        type="text"
                        value={digit}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        onClick={() => handleClick(index)}
                        onPaste={handlePaste}
                        maxLength={1}
                        onKeyUp={handleKeyPress}
                        className="w-10 h-14 text-center border border-gray-300 rounded text-3xl"
                    />
                    {index == 2 && <p className="text-3xl">-</p>}
                    {index == 5 && <p className="text-3xl">-</p>}
                </>
            ))}
        </div>
    )
}

export default PhoneNumberInput
