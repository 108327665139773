import { ReactNode } from 'react'
import { Plus } from '../icons/Plus'

interface HeaderComponentProps {
    title: string
    rightButton?: () => ReactNode
    children: ReactNode
}
const InfoBubble: React.FC<HeaderComponentProps> = ({ title, children, rightButton }) => {
    return (
        <div className="w-11/12 bg-white rounded-lg flex flex-col items-center mt-5 shadow-xl border-gray-200 border">
            <div className="flex flex-row items-center justify-center w-full px-2">
                <h1 className="text-black text-xl p-2">{title}</h1>
            </div>
            <div className="bg-gray-300 h-px w-full mb-5" />
            {children}
        </div>
    )
}

export default InfoBubble
