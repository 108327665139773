import { ReactNode, createContext, useContext } from 'react'
import useCacheContext from '../hooks/CacheHook'
import Customer from '../models/Customer'
import { IsEmployeeProvider } from './AuthContext'

const CustomerListProvider = createContext<Customer[] | null>(null)

interface CustomerListContextProps {
    children: ReactNode
}
const CustomerListContext: React.FC<CustomerListContextProps> = ({ children }) => {
    let isEmployee = useContext(IsEmployeeProvider)
    let [customers, error, isLoading, reloadCustomers] = useCacheContext<Customer[]>(
        '/v1/admin/customers',
        (val) => {
            return val.customers
        },
        null,
        true,
        isEmployee ?? false,
        'customerCache'
    )

    return <CustomerListProvider.Provider value={customers}>{children}</CustomerListProvider.Provider>
}

export { CustomerListProvider, CustomerListContext }
