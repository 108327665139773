import JobStatus from '../../models/JobStatus'

import { CalendarIcon } from '../icons/CalendarIcon'
import { CheckCircleIcon } from '../icons/CheckCircle'
import { SparklesIcon } from '../icons/SparklesIcon'
import Address from '../../models/Address'
import useNav, { useGoToDetail } from '../../hooks/NavigationHook'
import Tooltip from '../tooltip/Tooltip'
import MoreButton from '../moreButton/MoreButton'
import { ReactNode } from 'react'

interface TableRowProps {
    onClick: () => void
    children: ReactNode
}
const TableRow: React.FC<TableRowProps> = ({ onClick, children }) => {
    let navigate = useNav()

    return (
        <div
            className="w-full"
            onClick={() => {
                onClick()
            }}
        >
            <div className="flex p-5 flex-row justify-between cursor-pointer hover:bg-gray-100 rounded-lg">{children}</div>
        </div>
    )
}

export default TableRow
