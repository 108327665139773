import React from 'react'
import Address from '../../models/Address'
import { AddressLine1, AddressLine2 } from '../../models/Invoice'

interface StandardButtonProps {
    address: Address
}
const AddressLabel: React.FC<StandardButtonProps> = ({ address }) => {
    return (
        <h1 className="">
            <strong>{AddressLine1(address)}</strong>
            <br />
            {AddressLine2(address)}
        </h1>
    )
}
export default AddressLabel
