import { useLocation } from 'react-router-dom'
import Toggle from '../../components/toggle/Toggle'
import InfoBubble from '../../components/infoBubble/InfoBubble'
import TeamMember from '../../models/TeamMember'
import { Plus } from '../../components/icons/Plus'
import { useContext, useEffect, useState } from 'react'
import { IsAdminProvider } from '../../contexts/AppContext'
import axios from 'axios'
import MoreButton from '../../components/moreButton/MoreButton'
import useCacheContext from '../../hooks/CacheHook'
import useLocalDataContext from '../../hooks/LocalSettingHook'
import useNav from '../../hooks/NavigationHook'
import { IsEmployeeProvider } from '../../contexts/AuthContext'
import { CustomerPreferences } from '../../models/CustomerPreferences'
import { GetCustomerPreferences, PutCustomerPreferences } from '../../api/CustomerPreferences'
import { useCurrentCustomer } from '../../hooks/CurrentCustomerHook'
import Customer from '../../models/Customer'
import TableRow from '../../components/Row/TableRow'

const CustomerSettingsPage: React.FC = () => {
    let navigate = useNav()

    const currentCustomer = useCurrentCustomer()

    let [customerPreferences, setCustomerPreferences] = useState<CustomerPreferences>()

    let isAdmin = useContext(IsAdminProvider)
    let location = useLocation()

    let [teamMembers, teamError, isLoadingTeam, reloadTeams] = useCacheContext<TeamMember[]>(
        '/v1/users',
        (response) => {
            return response.users
        },
        null,
        true,
        isAdmin
    )

    const activateButton = (activate: boolean, member: TeamMember) => {
        return (
            <TableRow
                onClick={async () => {
                    await axios.post('/v1/user/activate', {
                        userId: member.id,
                        active: activate
                    })
                    await reloadTeams()
                }}
            >
                <p className="text-red-600 w-full text-start">{activate ? 'Activate' : 'Deactivate'}</p>
            </TableRow>
        )
    }

    const deleteButton = (member: TeamMember) => {
        return (
            <TableRow
                onClick={async () => {
                    await axios.post('/v1/user/delete', {
                        userId: member.id
                    })
                    await reloadTeams()
                }}
            >
                <p className="text-red-600 w-full text-start">{'Delete'}</p>
            </TableRow>
        )
    }

    const menuOptions = (member: TeamMember) => {
        if (!member.active) {
            return (
                <div className="p-2">
                    {activateButton(true, member)}
                    {deleteButton(member)}
                </div>
            )
        } else if (!member.acceptedInvite) {
            return (
                <div className="p-2">
                    <TableRow
                        onClick={async () => {
                            const result = await axios.post('/v1/user/invite/resend', {
                                userId: member.id
                            })
                        }}
                    >
                        <button className="w-full">Resend Invite</button>
                    </TableRow>
                    {activateButton(false, member)}
                    {deleteButton(member)}
                </div>
            )
        } else {
            return (
                <div className="p-2">
                    {activateButton(false, member)}
                    {deleteButton(member)}
                </div>
            )
        }
    }

    const loadCustomerPreferences = async (id: string) => {
        try {
            let response = await GetCustomerPreferences()
            setCustomerPreferences(response.preferences)
        } catch (e: any) {
            console.log(e)
        }
    }

    const updateCustomerPreferences = async (prefs: CustomerPreferences) => {
        console.log(prefs)
        try {
            let response = await PutCustomerPreferences(prefs)
            if (!response) {
                console.log('failed to update customer preferences')
            }
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        reloadTeams()
        loadCustomerPreferences(currentCustomer?.id ?? '')
    }, [currentCustomer, location])

    const handleToggleChange = (key: string, toggled: boolean) => {
        console.log('in func')
        console.log(toggled)
        if (customerPreferences) {
            setCustomerPreferences({
                ...customerPreferences,
                notificationSettings: {
                    ...customerPreferences.notificationSettings,
                    onSegmentStartAndEnd: toggled
                }
            })

            console.log('new customer prefs - ')
            console.log(customerPreferences)
        }
    }

    return (
        <div>
            {/* Page content */}
            <div className="bg-gray-100 flex flex-col items-center">
                <div className="flex flex-col items-center w-full">
                    {' '}
                    <InfoBubble title="Communication Preferences">
                        {customerPreferences != null && (
                            <div className="w-full">
                                <div className="flex flex-row items-center justify-between w-full p-5">
                                    <p className="text-black text-xl p-2">Notify on Job start</p>
                                    <Toggle
                                        value={customerPreferences.notificationSettings.onJobStart}
                                        setToggled={(value) => {
                                            if (customerPreferences) {
                                                let newPrefs = {
                                                    ...customerPreferences,
                                                    notificationSettings: {
                                                        ...customerPreferences.notificationSettings,
                                                        onJobStart: value
                                                    }
                                                }
                                                setCustomerPreferences(newPrefs)
                                                updateCustomerPreferences(newPrefs)
                                            }
                                        }}
                                    />
                                </div>

                                <div className="flex flex-row items-center justify-between w-full p-5">
                                    <p className="text-black text-xl p-2">Notify on Job end</p>
                                    <Toggle
                                        value={customerPreferences.notificationSettings.onJobEnd}
                                        setToggled={(value) => {
                                            if (customerPreferences) {
                                                let newPrefs = {
                                                    ...customerPreferences,
                                                    notificationSettings: {
                                                        ...customerPreferences.notificationSettings,
                                                        onJobEnd: value
                                                    }
                                                }
                                                setCustomerPreferences(newPrefs)
                                                updateCustomerPreferences(newPrefs)
                                            }
                                        }}
                                    />
                                </div>

                                <div className="flex flex-row items-center justify-between w-full p-5">
                                    <p className="text-black text-xl p-2">Notify on segment start and end</p>
                                    <Toggle
                                        value={customerPreferences.notificationSettings.onSegmentStartAndEnd}
                                        setToggled={(value) => {
                                            console.log('setToggled val')
                                            console.log(value)
                                            if (customerPreferences) {
                                                let newPrefs = {
                                                    ...customerPreferences,
                                                    notificationSettings: {
                                                        ...customerPreferences.notificationSettings,
                                                        onSegmentStartAndEnd: value
                                                    }
                                                }
                                                setCustomerPreferences(newPrefs)
                                                updateCustomerPreferences(newPrefs)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </InfoBubble>
                </div>

                {isAdmin && (
                    <InfoBubble title="Team Members">
                        {teamMembers != null &&
                            teamMembers.map((member) => {
                                return (
                                    <div key={member.id} className="flex flex-row items-center justify-between w-full p-5">
                                        <div>
                                            <p>
                                                <strong>{member.name === '' ? 'No name' : member.name}</strong>
                                                <br />
                                                {member.email}
                                                <br />
                                                {member.role}
                                            </p>
                                        </div>
                                        <div className="flex flex-row justify-center items-center p-5">
                                            <p className="pr-2">{Status(member)}</p>
                                            <MoreButton>{menuOptions(member)}</MoreButton>
                                        </div>
                                    </div>
                                )
                            })}
                        <button
                            className="w-full"
                            onClick={() => {
                                navigate('/settings/modal/addTeamMember')
                            }}
                        >
                            <div className="flex flex-row mb-4 justify-center items-center w-full">
                                <Plus className="fill-black w-6 h-6" />
                                <p>
                                    <strong>Add Team Member</strong>
                                </p>
                            </div>
                        </button>
                    </InfoBubble>
                )}
            </div>
        </div>
    )
}

function Status(member: TeamMember) {
    if (!member.active) {
        return 'Deactivated'
    } else if (!member.acceptedInvite) {
        return 'Invited'
    } else {
        return 'Active'
    }
}

export default CustomerSettingsPage
