import InfoBubble from '../../components/infoBubble/InfoBubble'
import { useContext, useMemo, useState } from 'react'

import useCacheContext from '../../hooks/CacheHook'
import Customer from '../../models/Customer'
import useNav from '../../hooks/NavigationHook'
import { ClearNetworkCacheProvider } from '../../contexts/AppContext'
import SidePanelLayout from '../../components/sidePanel/SidePanelLayout'
import TableRow from '../../components/Row/TableRow'
import FuzzySearchComponent from '../../components/FuzzySearchComponent/FuzzySearchComponent'
import MoreButton from '../../components/moreButton/MoreButton'
import { IsEmployeeProvider } from '../../contexts/AuthContext'
import { CustomerListProvider } from '../../contexts/CustomerContext'
import StandardButton from '../../components/button/StandardButton'

function AdminHomePage() {
    let isEmployee = useContext(IsEmployeeProvider)
    let customers = useContext(CustomerListProvider)

    let [filterRecent90Days, setFilterRecent90Days] = useState(true)

    let [filteredCustomer, setFilteredCustomers] = useState<Customer[]>([])

    const customersCleaned = useMemo(() => {
        return (
            (customers ?? [])
                .filter((a) => {
                    const mostRecentJob = new Date(a.mostRecentJobDate)
                    const today = new Date()
                    const ninetyDaysAgo = new Date(today.setDate(today.getDate() - 90))

                    return a.company !== '' && (!filterRecent90Days || mostRecentJob > ninetyDaysAgo)
                })
                ?.sort((a, b) => a.company.localeCompare(b.company)) ?? []
        )
    }, [customers, filterRecent90Days])
    let networkCacheClear = useContext(ClearNetworkCacheProvider)
    let navigate = useNav()
    let [selectedCompany, setSelectedCompany] = useState('')

    return (
        <SidePanelLayout
            sidePanel={
                <InfoBubble title="Settings">
                    <TableRow
                        onClick={() => {
                            navigate('/admin/home/portalSettings')
                        }}
                    >
                        Portal Settings
                    </TableRow>
                    <TableRow
                        onClick={() => {
                            navigate('/admin/preinspection/' + crypto.randomUUID())
                        }}
                    >
                        View All Jobs
                    </TableRow>
                </InfoBubble>
            }
        >
            <InfoBubble title="Choose Account to View">
                <div className="w-full px-10">
                    <FuzzySearchComponent
                        data={customersCleaned}
                        didFilterItems={(customers) => {
                            setFilteredCustomers(customers)
                        }}
                        keys={['company']}
                    />
                </div>
                {customers != null && (
                    <div className="flex flex-col w-full">
                        {filteredCustomer.map((customer, index) => {
                            return (
                                <TableRow
                                    onClick={() => {
                                        networkCacheClear()
                                        navigate('/' + customer.id)
                                    }}
                                >
                                    <div
                                        className={'flex flex-row w-full items-center justify-between'}
                                        style={{ zIndex: filteredCustomer.length - index + 20 }}
                                    >
                                        <p>{customer.company}</p>
                                        <MoreButton>
                                            <div className="flex flex-col w-full">
                                                <a
                                                    className="w-full py-2 hover:bg-gray-100"
                                                    href={'https://pro.housecallpro.com/pro/customers/' + customer.id}
                                                    target="_blank"
                                                >
                                                    Open in HCP
                                                </a>
                                                <button
                                                    className="w-full py-2 hover:bg-gray-100"
                                                    onClick={() => navigate('modal/' + (customer?.id ?? '') + '/addTeamMember')}
                                                >
                                                    Invite user
                                                </button>
                                                <button
                                                    className="w-full py-2 hover:bg-gray-100"
                                                    onClick={() => navigate('/' + (customer?.id ?? '') + '/settings')}
                                                >
                                                    Customer Settings
                                                </button>
                                            </div>
                                        </MoreButton>
                                    </div>
                                </TableRow>
                            )
                        })}
                        <div className="w-full p-10">
                            <StandardButton
                                text={filterRecent90Days ? 'Show all customers' : 'Show only recent customers'}
                                isLoading={false}
                                fullWidth={true}
                                onClick={() => {
                                    setFilterRecent90Days(!filterRecent90Days)
                                }}
                            ></StandardButton>
                            <p className="text-center pt-4">
                                {filterRecent90Days
                                    ? 'This list is filtered by customers with jobs created in the last 90 days'
                                    : 'Press the button above to filter customers with jobs in the last 90 days'}
                            </p>
                        </div>
                    </div>
                )}
                {customers == null && (
                    <div className="flex flex-col w-full">
                        <p className="text-center text-xl p-20">Loading customers...</p>
                    </div>
                )}
            </InfoBubble>
        </SidePanelLayout>
    )
}

export default AdminHomePage
