import { matchRoutes, useLocation } from 'react-router-dom'
import useDynamicRoutes from '../../hooks/useDynamicRoutes'
import { useNavigateOptions } from '../../hooks/NavigationHook'

const Footer: React.FC = () => {
    let location = useLocation()

    const path = location.pathname
    const splitRoutes = path.split('/modal/')
    const isModal = splitRoutes.length > 1
    const pathBeforeModal = splitRoutes[0]
    const pathAferModal = isModal ? '/' + splitRoutes[1] : '/'
    const dynamicRoutes = useDynamicRoutes()
    let { navigateUser } = useNavigateOptions()

    let allState = { ...location.state }

    const underLocation = {
        pathname: pathBeforeModal,
        state: allState,
        hash: location.hash,
        key: location.key
    }

    const route = matchRoutes(
        Object.values(dynamicRoutes).map((val) => {
            return {
                path: val.route
            }
        }),
        underLocation
    )

    const routeInfo = dynamicRoutes.find((val) => {
        return route != null && val.route == route[0].route.path
    })

    if (routeInfo?.footerHidden == true) {
        return <></>
    }

    return (
        <div className="bg-deepBlue x-auto py-8 sm:px-6 lg:px-8 h-18 flex flex-row items-center">
            <div className="flex flex-grow justify-between items-center w-full">
                <div>
                    <a href="https://magicmakereadies.com" target="_blank" rel="noopener noreferrer">
                        <div className="h-12 w-12">
                            <img src="https://www.magicmakereadies.com/wp-content/uploads/2022/06/logo.png" alt="Magic Make Readies logo" />
                        </div>
                    </a>
                </div>

                <div className="flex-col flex space-y-6">
                    <p className="font-semibold text-white text-xl">Call us at 804-362-8300</p>
                    <p className="text-sm text-coolGray w-128">
                        This customer portal is a service provided by Magic Make Readies. <br /> Copyright Magic Make Readies, Inc. All Rights Reserved
                    </p>
                </div>
                <div>
                    <p></p>
                </div>
            </div>
        </div>
    )
}

export default Footer
