import React, { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Field } from '../Models/FormModels'

interface DateComponentProps {
    field: Field
    selectedDate: Date | null
    onChange: (date: Date | null) => void
}

interface ExampleCustomInputProps {
    value?: string
    onClick?: () => void
}

const DateComponent: React.FC<DateComponentProps> = ({ field, selectedDate, onChange }) => {
    let { prompt, subprompt } = field
    const ExampleCustomInput = forwardRef<HTMLButtonElement, ExampleCustomInputProps>(({ value, onClick }, ref) => (
        <button className="w-full bg-gray-100 px-4 py-2 rounded-md" onClick={onClick} ref={ref}>
            {value == null || value == '' ? 'Choose Date' : value}
        </button>
    ))
    return (
        <div className="p-4">
            {prompt != null && <div className="font-semibold text-lg">{prompt}</div>}
            {subprompt != null && <div className="text-sm text-gray-600 mb-2">{subprompt}</div>}
            <DatePicker
                selected={selectedDate}
                onChange={(date: any) => onChange(date)}
                dateFormat="MMMM d, yyyy"
                className="form-input bg-red-400"
                maxDate={new Date()}
                customInput={<ExampleCustomInput />}
            />
        </div>
    )
}

export default DateComponent
