import Dropdown from '../../components/dropdown/Dropdown'
import StandardButton from '../../components/button/StandardButton'
import { useContext, useState } from 'react'
import { RequestClose } from '../../contexts/ModalContext'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
import AddTeamMemberResponse from '../../api/AddTeamMember/AddTeamMemberResponse'
import axios from 'axios'
import { useCurrentCustomer } from '../../hooks/CurrentCustomerHook'
import PhoneNumberInput from '../../components/PhoneInput/PhoneInput'

const PhoneNumberValidationPage: React.FC = () => {
    let close = useContext(RequestClose)
    let [isLoading, setIsLoading] = useState(false)

    let [phoneNumber, setPhoneNumber] = useState('')
    let [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false)

    let submit = async () => {
        setIsLoading(true)
        await axios.post('/v1/user/settings/communication', {
            phone_number: phoneNumber,
            text: true
        })
        setIsLoading(false)
        close()
    }

    return (
        <div className="bg-white h-full w-full p-5 flex flex-col justify-between items-center">
            <ModalCompatibleTitle title="Text Update Opt In" />
            <p className="pb-20">Enter your phone number below to recieve text updates on your work orders.</p>
            <PhoneNumberInput
                onChange={(phone) => {
                    setPhoneNumber(phone)
                }}
                isValid={(valid) => {
                    setIsValidPhoneNumber(valid)
                }}
                onSubmit={() => {
                    submit()
                }}
            />
            <div className="flex flex-row w-full pt-20 px-20 items-center">
                <input type="checkbox" className="" checked={true} onChange={() => {}} />

                <p className="pl-4 pb-2">
                    I agree to recieve text message updates from Magic Make Readies about my work orders. Messaging and data rates may apply. I understand that
                    I can opt out at any time on this customer portal or by replying STOP.{' '}
                </p>
            </div>
            <div className="w-full pb-20 px-20 items-center">
                <StandardButton
                    text="Submit"
                    isLoading={isLoading}
                    enabled={isValidPhoneNumber}
                    fullWidth={true}
                    onClick={async () => {
                        submit()
                    }}
                />
            </div>
        </div>
    )
}

export default PhoneNumberValidationPage
