export enum FieldType {
    Description = 'description',
    TextField = 'textfield',
    YesNo = 'yes_no',
    Date = 'date',
    MultiChoice = 'multichoice',
    Photo = 'photo'
}

export interface Field {
    id?: string
    type?: FieldType
    required?: boolean
    prompt?: string
    subprompt?: string
    options?: Option[]
    allowMultiSelect?: boolean
    showConditional?: Conditional
    commentConditional?: CommentConditional
}

export interface Option {
    id: string
    value: string
}

export enum ConditionalType {
    GreaterThan = 'greater_than',
    LessThan = 'less_than',
    Equal = 'equal',
    AnyOf = 'any_of',
    AllOf = 'all_of',
    Empty = 'empty',
    NotEmpty = 'not_empty'
}

export interface Conditional {
    id?: string
    type?: ConditionalType
    optionIds?: string[]
}

export interface CommentConditional {
    type?: ConditionalType
    optionIds?: string[]
    comment?: string
}

export function HandleConditionalForOption(options: Option[], conditional: Conditional): boolean {
    let shouldShow = true
    switch (conditional.type) {
        case ConditionalType.GreaterThan:
            // Handle GreaterThan condition
            break
        case ConditionalType.LessThan:
            // Handle LessThan condition
            break
        case ConditionalType.Equal:
            // Handle Equal condition
            break
        case ConditionalType.AnyOf:
            shouldShow =
                conditional.optionIds?.find((item) => {
                    return (
                        options.find((data) => {
                            return data.id == item
                        }) != undefined
                    )
                }) != undefined

            break
        case ConditionalType.AllOf:
            // Handle AllOf condition
            break
        case ConditionalType.Empty:
            // Handle Empty condition
            break
        case ConditionalType.NotEmpty:
            // Handle NotEmpty condition
            break
        default:
            // Handle default case if needed
            break
    }

    return shouldShow
}

// Updated type guards
export function isOptionArray(value: any): value is Option[] {
    return Array.isArray(value) && value.length > 0 && typeof value[0].id === 'string' && typeof value[0].value === 'string'
}

export interface ImageData {
    blob: Blob
    width: number
    height: number
}

export function isImageDataArray(value: any): value is ImageData[] {
    return (
        Array.isArray(value) && value.length > 0 && value[0].blob instanceof Blob && typeof value[0].width === 'number' && typeof value[0].height === 'number'
    )
}

export function isNumber(value: any): value is number {
    return typeof value === 'number'
}

export function isNull(value: any): value is null {
    return value === null
}
