import axios from 'axios'

export interface PostMappingResponse {
    success: boolean
}

// type MapRule struct {
// 	MatchText string `bson:"match_text" json:"matchText"`
// 	NewValue  string `bson:"new_value" json:"newValue"`
// }
export interface MapRule {
    id: string
    matchText: string
    newValue: string
}

export interface GetMappingsResponse {
    mappings: MapRule[]
}

export async function GetMappings(): Promise<MapRule[]> {
    let response: GetMappingsResponse = (await axios.get('/v1/admin/boardMappings')).data
    return response.mappings
}

export async function InsertMap(matchText: string, newValue: string) {
    await axios.post('/v1/admin/boardMappings', { matchText: matchText, newValue: newValue })
}
export async function UpdateMap(id: string, matchText: string, newValue: String) {
    await axios.post('/v1/admin/boardMappings/update', { id: id, matchText: matchText, newValue: newValue })
}
