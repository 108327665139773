import React from 'react'
import { Field, Option } from '../Models/FormModels'

interface MultiChoiceComponentProps {
    selectedOptions: Option[]
    field: Field
    onChange: (selected: Option[]) => void
}

const MultiChoiceComponent: React.FC<MultiChoiceComponentProps> = ({ selectedOptions, field, onChange }) => {
    let { prompt, subprompt, options, allowMultiSelect } = field
    options = options ?? []

    const handleOptionChange = (selectedOption: Option) => {
        if (allowMultiSelect) {
            const newSelection = selectedOptions.some((opt) => opt.id === selectedOption.id)
                ? selectedOptions.filter((opt) => opt.id !== selectedOption.id)
                : [...selectedOptions, selectedOption]
            onChange(newSelection)
        } else {
            onChange([selectedOption])
        }
    }

    const isChecked = (option: Option) => {
        return selectedOptions.some((selected) => selected.id === option.id)
    }

    return (
        <div className="p-4">
            {prompt != null && <div className="font-semibold text-lg">{prompt}</div>}
            {subprompt != null && <div className="text-sm text-gray-600 mb-2">{subprompt}</div>}
            {options.map((option) => (
                <div key={option.id} className="flex items-center mb-2">
                    <input
                        type={allowMultiSelect ? 'checkbox' : 'radio'}
                        id={option.id}
                        name={!allowMultiSelect ? field.id : undefined}
                        checked={isChecked(option)}
                        onChange={() => handleOptionChange(option)}
                        className={`form-${allowMultiSelect ? 'checkbox' : 'radio'} h-5 w-5 text-blue-600`}
                    />
                    <label htmlFor={option.id} className="ml-2 text-sm text-gray-700">
                        {option.value}
                    </label>
                </div>
            ))}
        </div>
    )
}

export default MultiChoiceComponent
