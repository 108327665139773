import React, { useState, ReactNode, MouseEventHandler } from 'react'
import { EllipsisHorizontalIcon } from '../icons/EllipsisHorizontalIcon'
import { FocusOn } from 'react-focus-on'
import { DivElementAttributes } from 'react-photo-album'

type MoreButtonProps = {
    displayComponent?: ReactNode
    defaultHoveFunctionality?: boolean
    children: ReactNode
}

const MoreButton: React.FC<MoreButtonProps> = ({
    displayComponent = <EllipsisHorizontalIcon className="h-5 w-5" />,
    defaultHoveFunctionality = true,
    children
}) => {
    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = (event: any) => {
        event.stopPropagation() // This prevents the click from propagating to parent elements
        setShowMenu(!showMenu)
    }

    return (
        <FocusOn
            enabled={showMenu}
            onClickOutside={(e) => {
                if (e instanceof MouseEvent) {
                    setShowMenu(false)
                }
            }}
            allowPinchZoom={true}
            onEscapeKey={(event) => {
                setShowMenu(false)
            }}
        >
            <div className="relative inline-block text-left">
                <button
                    onClick={toggleMenu}
                    className={
                        'p-2 rounded-full ' +
                        (defaultHoveFunctionality ? 'hover:bg-gray-200 focus:ring-2 focus:outline-none focus:ring-offset-2 focus:ring-indigo-500' : '')
                    }
                    aria-expanded={showMenu}
                >
                    {displayComponent}
                </button>

                {showMenu && (
                    <button
                        onClick={(event) => {
                            setShowMenu(false)
                            event.stopPropagation()
                        }}
                        className="z-50"
                    >
                        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {children}
                            </div>
                        </div>
                    </button>
                )}
            </div>
        </FocusOn>
    )
}

export default MoreButton
