// App.tsx or any other component
import React, { useContext, useMemo } from 'react'
import ExcelStyleTable from '../../components/classicTable/ClassicTable'
import useCacheContext from '../../hooks/CacheHook'
import { BoardItem, BoardItemColor, Invoice } from '../../models/Invoice'
import { useCurrentCustomer } from '../../hooks/CurrentCustomerHook'
import { CustomerListProvider } from '../../contexts/CustomerContext'
import { Cog } from '../../components/icons/Cog'
import useNav from '../../hooks/NavigationHook'
import { MapRule } from '../../api/MakeReadyMappings'
import JobStatus, { JobStatusColor } from '../../models/JobStatus'
import MapKeys from '../../components/mapKey/MapKey'
import { ConvertUnixToReadableDate } from '../../utility/dates'

function isBoardItem(obj: any): obj is BoardItem {
    // perform checks to determine if obj is a BoardItem
    return obj && typeof obj.name !== 'undefined' // example check
}

const AdminMakeReadyPage = () => {
    let [rawJobs, error, isLoading] = useCacheContext<Invoice[]>('/v1/admin/invoice/all', (val) => {
        return val.invoices
    })
    let [mappings, mapError, isLoadingMapping, reloadMappings] = useCacheContext<MapRule[]>(
        '/v1/admin/boardMappings',
        (val) => {
            return val.mappings
        },
        [],
        true,
        true,
        'mapCache'
    )
    let nav = useNav()
    let customers = useContext(CustomerListProvider)
    let customer = useCurrentCustomer()

    const boardColumns = useMemo(() => {
        let boardItemNames: string[] = []

        mappings?.forEach((item) => {
            boardItemNames.push(item.newValue)
        })

        boardItemNames = ['Customer', 'Address'].concat(boardItemNames).concat(['HCP'])

        return boardItemNames
    }, [rawJobs])

    const rows = useMemo(() => {
        let rows: { [key: string]: BoardItem | string | null }[] = []
        rawJobs?.forEach((item) => {
            if (item.status != 'inProgress' && item.status != 'upcoming') {
                return
            }
            var row: { [key: string]: BoardItem | string | null } = {}

            boardColumns.forEach((item) => {
                row[item] = null
            })

            if (item.boardItems != null) {
                item.boardItems.forEach((boardItem) => {
                    row[boardItem.name] = boardItem
                })
            }
            row['Customer'] = (customers ?? []).find((customer) => customer.id == item.customerId)?.company ?? ''
            row['Address'] = item.address.street
            row['HCP'] =
                item.jobIds.find((val) => {
                    return true
                }) ?? ''
            rows.push(row)
        })

        return rows.sort((a, b) => (a['Customer'] as string).localeCompare(b['Customer'] as string))
    }, [rawJobs])

    const columns = boardColumns
    const data = rows

    return (
        <div className="h-80v">
            <div className="w-full flex flex-col items-center font-semibold">
                <h1 className="text-2xl p-5 text-center">Make Ready Board</h1>
                <button
                    onClick={() => {
                        nav('/admin/home/portalSettings')
                    }}
                >
                    Edit Mappings
                </button>
            </div>
            <div className="w-full flex flex-row justify-center items-center">
                {
                    <MapKeys
                        items={[
                            { name: 'Unscheduled', color: JobStatusColor(JobStatus.Upcoming, true) },
                            { name: 'Upcoming', color: JobStatusColor(JobStatus.Upcoming, false) },
                            { name: 'In Progress', color: JobStatusColor(JobStatus.InProgress, false) },
                            { name: 'Complete', color: JobStatusColor(JobStatus.Completed, false) }
                        ]}
                    />
                }
            </div>
            <ExcelStyleTable
                columns={columns}
                data={data}
                customRender={(key, value) => {
                    if (key == 'HCP' && value != '') {
                        return (
                            <a className="w-full text-blue-600" href={'https://pro.housecallpro.com/pro/jobs/' + value} target="_blank">
                                {' '}
                                link
                            </a>
                        )
                    }
                    if (value == null) {
                        return (
                            <div className={'w-full px-6 py-4 ' + JobStatusColor(JobStatus.Completed, false)}>
                                <p>X</p>
                            </div>
                        )
                    }
                    if (isBoardItem(value)) {
                        let boardItem = value as BoardItem

                        return (
                            <div className={'w-full px-6 py-4 ' + BoardItemColor(boardItem)}>
                                <p>{ConvertUnixToReadableDate(boardItem.date)}</p>
                            </div>
                        )
                    }
                    return (
                        <div>
                            <p>{value}</p>
                        </div>
                    )
                }}
            />
        </div>
    )
}

export default AdminMakeReadyPage
