import { ReactNode } from 'react'
import magicImage from '../magic.png'
interface LoggedOutComponentProps {
    children: ReactNode
}
const LoggedOutComponent: React.FC<LoggedOutComponentProps> = ({ children }) => {
    const backgroundImageUrl = 'https://www.magicmakereadies.com/wp-content/uploads/2018/10/apartment_unit_turning.jpg' // Replace with your image URL
    return (
        <div className="h-screen flex">
            <div className="flex flex-row w-full">
                <div className="bg-loginBackground w-full md:w-9/12 h-full flex items-center justify-center">
                    <div className="w-3/4 md:w-1/2">
                        <div className="flex flex-col items-center justify-center">
                            <img src={magicImage} alt="Magic Make Readies logo" className="w-32 p-2" />
                        </div>
                        {children}
                    </div>
                </div>
                <div
                    className="w-0 md:w-3/12 h-full"
                    style={{ backgroundImage: `url(${backgroundImageUrl})`, backgroundSize: 'cover', backgroundPosition: 'calc(50% - 100px) 50%' }}
                ></div>
            </div>
        </div>
    )
}

export default LoggedOutComponent
