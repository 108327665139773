import React, { forwardRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Field } from '../Models/FormModels'

interface TextFieldEntryProps {
    field: Field
    text: string
    onChange: (text: string) => void
}

const TextFieldEntry: React.FC<TextFieldEntryProps> = ({ field, text, onChange }) => {
    let { prompt, subprompt } = field

    return (
        <div className="p-4">
            {prompt != null && <div className="font-semibold text-lg">{prompt}</div>}
            {subprompt != null && <div className="text-sm text-gray-600 mb-2">{subprompt}</div>}
            <input
                type="text"
                className="p-2 border border-gray-300 rounded-md shadow-sm w-full"
                placeholder={''}
                value={text}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}

export default TextFieldEntry
