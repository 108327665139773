import create from '@kodingdotninja/use-tailwind-breakpoint'
import { useEffect } from 'react'

import screens from '../utility/tailwind.screens.ts'

const { useBreakpoint: useBreakpointOriginal } = create(screens)

export const useBreakpoint = (breakpoint) => {
    const isBreakpointOrLarger = useBreakpointOriginal(breakpoint)

    // Workaround for a bug with the use-tailwind-breakpoint library. See:
    // https://github.com/kodingdotninja/use-tailwind-breakpoint/issues/2#issuecomment-1030703188
    useEffect(() => {
        window.dispatchEvent(new Event('resize'))
    }, [])

    return isBreakpointOrLarger
}
