import { useEffect, useState } from 'react'
import { ErrorBase } from '../utility/error'
import useCacheContext from './CacheHook'

/*
    This hook is useful if you want data backed by the server,
    but you also want the ability to manipulate the data locally.
    Typically for something the user will eventually save to the server.
*/
export default function useLocalDataContext<T>(
    url: string,
    post: (data: T) => Promise<void> = async () => {},
    manipulate: (input: any) => T = (input) => {
        return input
    },
    defaultValue: T | null = null,
    shouldUseCache: Boolean = true,
    shouldLoad: Boolean = true
): [
    data: T | null,
    setData: React.Dispatch<React.SetStateAction<T | null>>,
    post: () => Promise<void>,
    error: ErrorBase | null,
    isLoading: boolean,
    reload: () => Promise<void>
] {
    let [networkData, error, isLoading, fetchData] = useCacheContext<T>(url, manipulate, defaultValue, shouldUseCache, shouldLoad)
    let [localData, setLocalData] = useState<T | null>(networkData)

    const postData = async (): Promise<void> => {
        if (localData != null) {
            await post(localData)
            await fetchData()
        }
    }

    useEffect(() => {
        setLocalData(networkData)
    }, [networkData])

    return [localData, setLocalData, postData, error, isLoading, fetchData]
}
