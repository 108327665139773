import React, { useEffect, useMemo, useState } from 'react'
import { Field, ImageData } from '../Models/FormModels'
import PhotoAlbum, { Photo } from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox'

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import { PhotoIcon } from '../../../../components/icons/PhotoIcon'

interface PhotoPickerComponentProps {
    field: Field
    blobs: ImageData[]
    onPhotosSelected: (photos: FileList | null) => void
}

export const PhotoPickerComponent: React.FC<PhotoPickerComponentProps> = ({ field, blobs, onPhotosSelected }) => {
    const [selectedPhotos, setSelectedPhotos] = useState<FileList | null>(null)
    let { prompt, subprompt } = field
    const [index, setIndex] = useState(-1)
    const [resetKey, setResetKey] = useState(1)

    const photos = useMemo(() => {
        return blobs.map((blob) => {
            return {
                src: URL.createObjectURL(blob.blob),
                width: blob.width
            } as Photo
        })
    }, [blobs])

    useEffect(() => {
        // Cleanup function to revoke URLs
        return () => {
            // photos.forEach((photo) => URL.revokeObjectURL(photo.src))
        }
    }, [photos])

    const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setSelectedPhotos(event.target.files)
            onPhotosSelected(event.target.files)
            setResetKey(resetKey + 1)
        }
    }

    return (
        <div className="p-4" id={field.id ?? ''}>
            <div className="font-semibold text-lg">{prompt}</div>
            <div className="text-sm text-gray-600 mb-2">{subprompt}</div>

            <label htmlFor={'photo-upload-' + field.id + '-' + resetKey} className="btn cursor-pointer ">
                <div className="flex flex-row">
                    <PhotoIcon className="fill-black w-6 h-6 mr-2" /> Upload
                </div>
            </label>
            <input type="file" id={'photo-upload-' + field.id + '-' + resetKey} multiple accept="image/*" onChange={handlePhotoChange} className="hidden" />
            {photos && (
                <>
                    <div className="w-full p-4">
                        <PhotoAlbum layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                    </div>
                    <Lightbox
                        slides={photos}
                        open={index >= 0}
                        index={index}
                        close={() => setIndex(-1)}
                        // enable optional lightbox plugins
                        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                    />
                </>
            )}
        </div>
    )
}
