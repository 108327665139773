import { useContext, useEffect, useState } from 'react'
import { Plus } from '../../components/icons/Plus'
import InfoBubble from '../../components/infoBubble/InfoBubble'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
import useNav from '../../hooks/NavigationHook'
import StandardButton from '../../components/button/StandardButton'
import { MapRule, InsertMap, UpdateMap } from '../../api/MakeReadyMappings'
import { RequestClose } from '../../contexts/ModalContext'
import useParamsHook from '../../hooks/GetParamsHook'
import useCacheContext from '../../hooks/CacheHook'

//...
function AddMappingPage() {
    let close = useContext(RequestClose)
    let params = useParamsHook()

    let mapId = params?.params.mapId
    let [mappings, error, isLoadingMapping, reloadMappings] = useCacheContext<MapRule[]>(
        '/v1/admin/boardMappings',
        (val) => {
            return val.mappings
        },
        [],
        true,
        mapId != null,
        'mapCache'
    )
    let [text, setText] = useState('')
    let [newValue, setNewValue] = useState('')
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        let currentMap = mappings?.find((map) => {
            return map.id == mapId
        })
        setText(currentMap?.matchText ?? '')
        setNewValue(currentMap?.newValue ?? '')
    }, [isLoadingMapping])

    return (
        <div className="w-full flex flex-col items-center">
            <ModalCompatibleTitle title={mapId != null ? 'Edit Mapping' : 'Add Mapping'} />

            <div className="flex flex-col p-2 w-full">
                <div className="mb-4 w-full">
                    <p>If a job has the line item that contains</p>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        type="text"
                        placeholder="Line item text"
                    />
                </div>

                <div className="mb-4 w-full">
                    <p>Then add this make ready board entry</p>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="name"
                        value={newValue}
                        onChange={(e) => setNewValue(e.target.value)}
                        type="text"
                        placeholder="Make Ready Board column"
                    />
                </div>

                <StandardButton
                    text="Submit"
                    isLoading={isLoading}
                    onClick={async () => {
                        setIsLoading(true)
                        try {
                            if (mapId != null) {
                                await UpdateMap(mapId, text, newValue)
                            } else {
                                await InsertMap(text, newValue)
                            }
                            close()
                        } catch (e: any) {
                            console.log('error')
                            setIsLoading(false)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default AddMappingPage
