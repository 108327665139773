/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { CACHE } from '../contexts/Cache' // Adjust the path as necessary
import { errorToErrorBase } from '../utility/errorTypes'
import { LogOutProvider } from '../contexts/AppContext'
import { ErrorBase } from '../utility/error'

export default function useCacheContext<T>(
    url: string,
    manipulate: (input: any) => T = (input) => {
        return input
    },
    defaultValue: T | null = null,
    shouldUseCache: Boolean = true,
    shouldLoad: Boolean = true,
    key: string = 'urlCache'
): [data: T | null, error: ErrorBase | null, isLoading: boolean, reload: () => Promise<void>] {
    const cacheID = url
    if (CACHE[key] == null) {
        CACHE[key] = {}
    }
    const defaultVal = CACHE[key][cacheID] != undefined && shouldUseCache ? CACHE[key][cacheID] : defaultValue
    const [data, setData] = useState<T | null>(defaultVal)
    const [error, setError] = useState<ErrorBase | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    let logOut = useContext(LogOutProvider)

    const fetchData = async (): Promise<void> => {
        setError(null)
        try {
            setIsLoading(true)
            const settings = await axios.get(url)
            let data = manipulate(settings.data) as T
            CACHE[key][cacheID] = data
            setData(data)
            setIsLoading(false)
        } catch (error: any) {
            let baseError = errorToErrorBase(error)

            switch (baseError.message) {
                case 'LOGGED_OUT':
                    logOut()
                    break
                default:
            }

            setError(baseError)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        // look in cache and set response if present
        if (CACHE[key][cacheID] != undefined && shouldUseCache) {
            setData(CACHE[key][cacheID])
            setError(null)
            setIsLoading(false)
        } else if (shouldLoad == true) {
            fetchData()
        }
    }, [url, shouldUseCache, shouldLoad])

    return [data, error, isLoading, fetchData]
}
