import React, { createContext, useContext, useEffect, useState } from 'react'

// Define a context and a provider
interface IndexedDBContextType {
    db: IDBDatabase | null
    error: Event | null
    isLoading: boolean
}

export const IndexedDBContext = createContext<IndexedDBContextType | undefined>(undefined)

interface IndexedDBProviderProps {
    children: React.ReactNode
}

export const IndexedDBProvider: React.FC<IndexedDBProviderProps> = ({ children }) => {
    const [db, setDb] = useState<IDBDatabase | null>(null)
    const [error, setError] = useState<Event | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        const request = indexedDB.open('mmr', 1)

        request.onupgradeneeded = (event) => {
            const db = request.result
            if (!db.objectStoreNames.contains('fieldDrafts')) {
                db.createObjectStore('fieldDrafts', { keyPath: 'id' })
            }
        }

        request.onsuccess = (event) => {
            setDb(request.result)
            setIsLoading(false)
        }

        request.onerror = (event) => {
            setError(event)
            setIsLoading(false)
        }
    }, [])

    return <IndexedDBContext.Provider value={{ db, error, isLoading }}>{children}</IndexedDBContext.Provider>
}
