import { useNavigate, useParams } from 'react-router-dom'
import InfoBubble from '../components/infoBubble/InfoBubble'
import Job from '../models/job'
import JobStatus from '../models/JobStatus'
import JobRow from '../components/jobRow/JobRow'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Invoice } from '../models/Invoice'
import useCacheContext from '../hooks/CacheHook'
import FuzzySearchComponent from '../components/FuzzySearchComponent/FuzzySearchComponent'
import useNav from '../hooks/NavigationHook'
import { useCurrentCustomer } from '../hooks/CurrentCustomerHook'
import SidePanelLayout from '../components/sidePanel/SidePanelLayout'
import TableRow from '../components/Row/TableRow'
import { IsEmployeeProvider } from '../contexts/AuthContext'

function HomeDashboardPage() {
    let navigate = useNav()
    let [rawJobs, error, isLoading] = useCacheContext<Invoice[]>('/v1/invoices', (val) => {
        return val.invoices
    })
    let isEmployee = useContext(IsEmployeeProvider)
    let [filteredJobs, setFilteredJobs] = useState<Invoice[]>([])
    let jobs = filteredJobs
    let customer = useCurrentCustomer()
    return (
        <SidePanelLayout
            sidePanel={
                <div className="w-full flex flex-col items-center">
                    {isEmployee && customer != null && (
                        <>
                            <InfoBubble title="Admin Quick Actions">
                                <TableRow onClick={() => navigate('modal/' + (customer?.id ?? '') + '/addTeamMember')}>Invite someone</TableRow>
                                <TableRow
                                    onClick={() => {
                                        navigate('settings')
                                    }}
                                >
                                    Change customer settings
                                </TableRow>
                                <TableRow onClick={() => {}}>
                                    <a className="w-full" href={'https://pro.housecallpro.com/pro/customers/' + customer.id} target="_blank">
                                        Open customer in HCP
                                    </a>
                                </TableRow>
                            </InfoBubble>
                        </>
                    )}
                    <InfoBubble title="Make Ready Board">
                        <button
                            className="pb-6 text-blue-700"
                            onClick={() => {
                                navigate('makeReady')
                            }}
                        >
                            View Make Ready Board
                        </button>
                    </InfoBubble>
                    <InfoBubble title="Having issues?">
                        <p className="p-4">Text us: {'804-362-8300'} </p>
                    </InfoBubble>
                </div>
            }
        >
            <div className="w-full flex flex-col items-center">
                <div className="w-11/12">
                    <FuzzySearchComponent
                        data={rawJobs ?? []}
                        didFilterItems={(items) => {
                            if (items.length == 0 && filteredJobs.length == 0) {
                                return
                            }
                            setFilteredJobs(items)
                        }}
                        keys={['address.street', 'address.city', 'address.state', 'address.zip']}
                    />
                </div>
                <InfoBubble title="In Progress">
                    {jobs != null &&
                        jobs
                            .filter((job) => {
                                return job.status == JobStatus.InProgress
                            })
                            .map((job) => {
                                return <JobRow key={job.id} invoice={job} />
                            })}
                    <div className="mb-6" />
                </InfoBubble>
                <InfoBubble title="Upcoming">
                    {jobs != null &&
                        jobs
                            .filter((job) => {
                                return job.status == JobStatus.Upcoming
                            })
                            .map((job) => {
                                return <JobRow key={job.id} invoice={job} />
                            })}
                    <div className="mb-6" />
                </InfoBubble>
                <InfoBubble title="Completed">
                    {jobs != null &&
                        jobs
                            .filter((job) => {
                                return job.status == JobStatus.Completed
                            })
                            .map((job) => {
                                return <JobRow key={job.id} invoice={job} />
                            })}
                    <div className="mb-6" />
                </InfoBubble>
            </div>
        </SidePanelLayout>
    )
}

export default HomeDashboardPage
