/* eslint-disable eqeqeq */
import { Location, PathMatch, matchPath, matchRoutes, useLocation } from 'react-router-dom'
import useDynamicRoutes from './useDynamicRoutes'

export default function useParamsHook(inputLocation: Location<any> | null = null): PathMatch<string> | null {
    let providedLocation = inputLocation
    let alternateLocation = useLocation()
    const dynamicRoutes = useDynamicRoutes()

    let location = providedLocation ?? alternateLocation

    if (location == null) {
        console.log('location is null')
        return null
    }
    const path = location.pathname
    const splitRoutes = path.split('/modal/')
    const isModal = splitRoutes.length > 1
    const pathBeforeModal = splitRoutes[0]
    const pathAferModal = isModal ? '/' + splitRoutes[1] : '/'

    let allState = { ...location.state }
    // const { pathname } = useLocation()

    const underLocation = {
        pathname: pathBeforeModal,
        state: allState,
        hash: location.hash,
        key: location.key
    }

    const route = matchRoutes(
        Object.values(dynamicRoutes).map((val) => {
            return {
                path: val.route
            }
        }),
        underLocation
    )

    const routeInfo = dynamicRoutes.find((val) => {
        return route != null && val.route == route[0].route.path
    })

    const pathVals = matchPath(routeInfo?.route ?? '', underLocation.pathname)

    return pathVals
}
