import React, { createContext, useState, ReactNode } from 'react'

import { AxiosError, AxiosResponse } from 'axios'

import axios from 'axios'

import LoginResponse from '../api/Login/LoginResponse'
import { useLocation } from 'react-router-dom'
import useNav from '../hooks/NavigationHook'
import Customer from '../models/Customer'
import { CACHE } from './Cache'
import useParamsHook from '../hooks/GetParamsHook'

let customerIDOverride = ''

axios.interceptors.request.use(async (req) => {
    let sessionString = localStorage.getItem('session')
    if (sessionString != null) {
        const session = JSON.parse(sessionString)
        req.headers['session_id'] = session['session_id']
        if (session['customer'] != null) {
            // If customer id is explicitly set then use that. Otherwise predict the customer id based on the path.
            req.headers['customer_id'] = req.headers['customer_id'] ?? customerIDOverride ?? ''
        }
    }
    return req
})

interface AxiosSessionContextProps {
    children: ReactNode
}

const AxiosSessionContext: React.FC<AxiosSessionContextProps> = ({ children }) => {
    let params = useParamsHook()
    customerIDOverride = params?.params['customerId'] as string

    return <>{children}</>
}

export { AxiosSessionContext }
