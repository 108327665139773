import { useContext, useState, useCallback } from 'react'
import { IndexedDBContext } from '../contexts/IndexDBContext'

interface FormData {
    // Define the structure of your form data here
    id?: string
    status: 'draft' | 'submitted'
    [key: string]: any // Flexible to accommodate various form structures
}

interface UseFormDataHook {
    addFormData: (data: FormData) => Promise<void>
    getFormData: (id: string) => Promise<FormData | undefined>
    isLoadingDB: boolean
    errorDB: DOMException | null
}

export function useFormData(): UseFormDataHook {
    const context = useContext(IndexedDBContext)
    if (!context) {
        throw new Error('useFormData must be used within an IndexedDBProvider')
    }
    const { db, isLoading } = context

    const [error, setError] = useState<DOMException | null>(null)

    const addFormData = useCallback(
        async (data: FormData) => {
            if (!db) throw new Error('Database not initialized')
            try {
                const transaction = db.transaction(['fieldDrafts'], 'readwrite')
                const store = transaction.objectStore('fieldDrafts')
                await new Promise((resolve, reject) => {
                    const request = store.put(data)
                    request.onsuccess = () => resolve(request.result)
                    request.onerror = (e: any) => reject(e.target.error)
                })
            } catch (err) {
                setError(err as DOMException)
            }
        },
        [db]
    )

    const getFormData = useCallback(
        async (id: string): Promise<FormData | undefined> => {
            if (!db) throw new Error('Database not initialized')
            try {
                const transaction = db.transaction(['fieldDrafts'], 'readonly')
                const store = transaction.objectStore('fieldDrafts')
                return await new Promise((resolve, reject) => {
                    const request = store.get(id)
                    request.onsuccess = () => resolve(request.result as FormData)
                    request.onerror = (e: any) => reject((e.target as IDBRequest).error) // Correctly type-casting to IDBRequest
                })
            } catch (err) {
                setError(err as DOMException)
                return undefined
            }
        },
        [db]
    )
    return { addFormData, getFormData, isLoadingDB: isLoading, errorDB: error }
}
