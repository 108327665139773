import { useEffect, useState } from 'react'
import { GetMappings, MapRule } from '../../api/MakeReadyMappings'
import TableRow from '../../components/Row/TableRow'
import { Plus } from '../../components/icons/Plus'
import InfoBubble from '../../components/infoBubble/InfoBubble'
import useNav from '../../hooks/NavigationHook'
import { useLocation } from 'react-router-dom'
import useCacheContext from '../../hooks/CacheHook'
import MoreButton from '../../components/moreButton/MoreButton'
import axios from 'axios'
import TeamMember from '../../models/TeamMember'

function AdminPortalSettings() {
    let navigate = useNav()
    let [mappings, error, isLoading, reloadMappings] = useCacheContext<MapRule[]>(
        '/v1/admin/boardMappings',
        (val) => {
            return val.mappings
        },
        [],
        true,
        true
    )

    let [employees, employeeListError, isLoadingEmployees, reloadEmployees] = useCacheContext<TeamMember[]>(
        '/v1/employee/list',
        (response) => {
            return response.users
        },
        null,
        true,
        true
    )

    let location = useLocation()
    useEffect(() => {
        reloadMappings()
        reloadEmployees()
    }, [location])

    const activateButton = (activate: boolean, member: TeamMember) => {
        return (
            <TableRow
                onClick={async () => {
                    await axios.post('/v1/user/activate', {
                        userId: member.id,
                        active: activate
                    })
                    await reloadEmployees()
                }}
            >
                <p className="text-red-600 w-full text-start">{activate ? 'Activate' : 'Deactivate'}</p>
            </TableRow>
        )
    }

    const deleteButton = (member: TeamMember) => {
        return (
            <TableRow
                onClick={async () => {
                    await axios.post('/v1/user/delete', {
                        userId: member.id
                    })
                    await reloadEmployees()
                }}
            >
                <p className="text-red-600 w-full text-start">{'Delete'}</p>
            </TableRow>
        )
    }

    const sendPasswordReset = (member: TeamMember) => {
        return (
            <TableRow
                onClick={async () => {
                    await axios.post('/v1/forgotPassword', {
                        email: member.email
                    })
                    await reloadEmployees()
                }}
            >
                <p className="w-full text-start">{'Send Password Reset'}</p>
            </TableRow>
        )
    }

    const menuOptions = (member: TeamMember) => {
        if (!member.active) {
            return (
                <div className="p-2">
                    {activateButton(true, member)}
                    {deleteButton(member)}
                </div>
            )
        } else if (!member.acceptedInvite) {
            return (
                <div className="p-2">
                    <TableRow
                        onClick={async () => {
                            const result = await axios.post('/v1/user/invite/resend', {
                                userId: member.id
                            })
                        }}
                    >
                        <button className="w-full">Resend Invite</button>
                    </TableRow>
                    {activateButton(false, member)}
                    {deleteButton(member)}
                </div>
            )
        } else {
            return (
                <div className="p-2">
                    {sendPasswordReset(member)}
                    {activateButton(false, member)}
                    {deleteButton(member)}
                </div>
            )
        }
    }

    return (
        <div className="w-full flex flex-col items-center min-h-80v mb-10">
            <InfoBubble title="Magic Make Readies Employees">
                {employees?.map((employee) => {
                    return (
                        <TableRow onClick={() => {}}>
                            <div key={employee.id} className="flex flex-row items-center justify-between w-full p-5">
                                <div>
                                    <p>
                                        <strong>{employee.name === '' ? 'No name' : employee.name}</strong>
                                        <br />
                                        {employee.email}
                                        <br />
                                        {employee.role}
                                    </p>
                                </div>
                                <div className="flex flex-row justify-center items-center p-5">
                                    <p className="pr-2">{Status(employee)}</p>
                                    <MoreButton>{menuOptions(employee)}</MoreButton>
                                </div>
                            </div>
                        </TableRow>
                    )
                })}
                <button
                    className="w-full"
                    onClick={() => {
                        navigate('modal/addEmployee')
                    }}
                >
                    <div className="flex flex-row mb-4 justify-center items-center w-full transition duration-500 hover:scale-110">
                        <Plus className="fill-black w-6 h-6" />
                        <p>
                            <strong>Add Team Member</strong>
                        </p>
                    </div>
                </button>
            </InfoBubble>

            <InfoBubble title="Make Ready Board Mappings">
                {mappings != null &&
                    mappings.map((rule, index, list) => {
                        return (
                            <TableRow
                                onClick={() => {
                                    navigate('modal/makereadymapping/' + rule.id)
                                }}
                                key={rule.id}
                            >
                                <div className="flex flex-row justify-between w-full">
                                    <div className="flex flex-col">
                                        <p>
                                            Line items contains: <strong>{rule.matchText}</strong>
                                        </p>
                                        <p>
                                            Add board item:
                                            <strong> {rule.newValue}</strong>
                                        </p>
                                    </div>
                                    <div style={{ zIndex: list.length - index }}>
                                        <MoreButton>
                                            <TableRow
                                                onClick={async () => {
                                                    navigate('modal/makereadymapping/' + rule.id)
                                                }}
                                            >
                                                Edit Mapping
                                            </TableRow>
                                            <TableRow
                                                onClick={async () => {
                                                    try {
                                                        await axios.post('/v1/admin/boardMappings/delete', { id: rule.id })
                                                        reloadMappings()
                                                    } catch {
                                                        console.log('Error deleting map')
                                                    }
                                                }}
                                            >
                                                <p className="text-red-500">
                                                    <strong>Delete Mapping</strong>
                                                </p>
                                            </TableRow>
                                        </MoreButton>
                                    </div>
                                </div>
                            </TableRow>
                        )
                    })}

                <button
                    className="w-full"
                    onClick={() => {
                        navigate('modal/makereadymapping')
                    }}
                >
                    <div className="flex flex-row mb-4 justify-center items-center w-full transition duration-500 hover:scale-110">
                        <Plus className="fill-black w-6 h-6" />
                        <p className="">
                            <strong>Add Mapping</strong>
                        </p>
                    </div>
                </button>
                <button
                    className="w-full"
                    onClick={() => {
                        navigate('modal/editMappingOrder')
                    }}
                >
                    <div className="flex flex-row mb-4 justify-center items-center w-full transition duration-500 hover:scale-110">
                        <p className="">
                            <strong>Reorder Mappings</strong>
                        </p>
                    </div>
                </button>
            </InfoBubble>
        </div>
    )
}

function Status(member: TeamMember) {
    if (!member.active) {
        return 'Deactivated'
    } else if (!member.acceptedInvite) {
        return 'Invited'
    } else {
        return 'Active'
    }
}

export default AdminPortalSettings
