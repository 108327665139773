import { useNavigate } from 'react-router-dom'
import LoggedOutComponent from './LoggedOutComponent'
import useNav from '../hooks/NavigationHook'

function NotFound() {
    const backgroundImageUrl = 'https://www.magicmakereadies.com/wp-content/uploads/2018/10/apartment_unit_turning.jpg' // Replace with your image URL
    const navigate = useNav()
    return (
        <LoggedOutComponent>
            <form>
                <div className="mb-4">
                    <h1>Page Not Found</h1>
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Back Home
                    </button>
                </div>
            </form>
        </LoggedOutComponent>
    )
}

export default NotFound
