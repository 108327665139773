import { useNavigate } from 'react-router-dom'

/*
    This hook is useful if you want data backed by the server,
    but you also want the ability to manipulate the data locally.
    Typically for something the user will eventually save to the server.
*/
export default function GoBackHook(): () => void {
    let navigate = useNavigate()

    return () => {
        navigate(-1)
    }
}
