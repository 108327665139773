import { useParams } from 'react-router-dom'
import LoggedOutComponent from '../LoggedOutComponent'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { AcceptInviteProvider } from '../../contexts/AppContext'
import { AxiosError, AxiosResponse } from 'axios'
import useNav from '../../hooks/NavigationHook'

type InviteRegisterParams = {
    inviteId: string
}

function InviteRegister() {
    let navigate = useNav()
    const { inviteId } = useParams<keyof InviteRegisterParams>() as InviteRegisterParams
    const [email, setEmail] = useState('')
    const [companyName, setCompanyName] = useState('')

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errorRetrievalMessage, setErrorRetrievalMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const acceptInvite = useContext(AcceptInviteProvider)

    useEffect(() => {
        const fetchInviteData = async () => {
            try {
                const settings = await axios.get('/v1/invite/info?inviteCode=' + inviteId)
                let data = settings.data

                setCompanyName(data.companyName)
                setEmail(data.email)
            } catch (error: any) {
                const err: AxiosError = error
                setErrorRetrievalMessage('Invite is invalid or already used')
            }
        }
        fetchInviteData()
    }, [])

    if (errorRetrievalMessage != '') {
        return (
            <LoggedOutComponent>
                <div>
                    <h1>{errorRetrievalMessage}</h1>
                </div>
            </LoggedOutComponent>
        )
    }

    return (
        <LoggedOutComponent>
            <form>
                <div className="flex flex-col items-center justify-center mb-4">
                    {companyName != '' && <h1 className="text-text text-xl">Company: {companyName}</h1>}
                    <h1 className="text-text text-xl">Email: {email}</h1>
                </div>

                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                </div>
                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="confirm-password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                    />
                </div>
                {errorMessage !== '' && <p>{errorMessage}</p>}
                <div className="flex flex-col-reverse md:flex-row items-center justify-between">
                    <button
                        className="text-link font-bold py-2 px-4"
                        type="button"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Back to login
                    </button>
                    <button
                        className="bg-loginAction text-black font-bold py-2 px-4 focus:outline-none focus:shadow-outline  w-full md:w-40 rounded-full"
                        type="button"
                        onClick={async () => {
                            setErrorMessage('')
                            if (password !== confirmPassword) {
                                setErrorMessage('Passwords need to match')
                                return
                            }
                            const result = await acceptInvite(inviteId, password)
                            if (result) {
                                navigate('/')
                            } else {
                                setErrorMessage('Failed to accept invite')
                            }
                        }}
                    >
                        Create Account
                    </button>
                </div>
            </form>
        </LoggedOutComponent>
    )
}

export default InviteRegister
