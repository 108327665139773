import { useParams } from 'react-router-dom'
import LoggedOutComponent from '../LoggedOutComponent'
import { useContext, useState } from 'react'
import { ForgotPasswordConfirmProvider } from '../../contexts/AppContext'
import useNav from '../../hooks/NavigationHook'

type ForgotPasswordConfirmParams = {
    code: string
}

function ForgotPasswordConfirmPage() {
    let navigate = useNav()
    const [password, setPassword] = useState('')
    const { code } = useParams<keyof ForgotPasswordConfirmParams>() as ForgotPasswordConfirmParams
    const [errorMessage, setErrorMessage] = useState('')
    let confirmPassword = useContext(ForgotPasswordConfirmProvider)
    return (
        <LoggedOutComponent>
            <form>
                <div className="flex flex-col items-center justify-center mb-4">
                    <h1 className="text-text text-xl">Enter your new password below</h1>
                </div>

                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                    />
                </div>
                {errorMessage !== '' && <p>{errorMessage}</p>}
                <div className="flex flex-col-reverse md:flex-row items-center justify-between">
                    <button
                        className="text-link font-bold py-2 px-4"
                        type="button"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Back to login
                    </button>
                    <button
                        className="bg-loginAction text-black font-bold py-2 px-4 focus:outline-none focus:shadow-outline  w-full md:w-40 rounded-full"
                        type="button"
                        onClick={async () => {
                            let success = await confirmPassword(code, password)
                            if (!success) {
                                setErrorMessage('Failed to reset password. Please go through forgot password flow again')
                            }
                        }}
                    >
                        Confirm
                    </button>
                </div>
            </form>
        </LoggedOutComponent>
    )
}

export default ForgotPasswordConfirmPage
