export function ConvertUnixToReadableDate(unixTimestamp: number): string {
    if (unixTimestamp <= 0) {
        return 'Unscheduled'
    }
    const date = new Date(unixTimestamp * 1000)
    const month = date.getMonth() + 1 // getMonth() returns 0-11, so add 1 for 1-12
    const day = date.getDate()

    // Format the date as MM/DD
    return `${month}/${day}`
}
