import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { IDBPDatabase, openDB } from 'idb'

// Define a type for your context state
type DBContextType = {
    db: IDBPDatabase | null
}

// Create the context with an initial undefined state
const DBContext = createContext<DBContextType | undefined>(undefined)

export const useDB = () => {
    const context = useContext(DBContext)
    if (!context) {
        throw new Error('useDB must be used within a DBProvider')
    }
    return context
}

interface DBProviderProps {
    children: ReactNode
}

export const DBProvider: React.FC<DBProviderProps> = ({ children }) => {
    const [db, setDb] = useState<IDBPDatabase | null>(null)

    useEffect(() => {
        // Initialize DB and set it in state
        openDB('mmr', 1, {
            upgrade(db) {
                if (!db.objectStoreNames.contains('fieldDrafts')) {
                    db.createObjectStore('fieldDrafts', { keyPath: 'id' })
                }
            }
        }).then(setDb)
    }, [])

    return <DBContext.Provider value={{ db }}>{children}</DBContext.Provider>
}
