import React from 'react'
import Loading from '../loading/Loading'

interface StandardButtonProps {
    text: string
    isLoading: boolean
    enabled?: boolean
    fullWidth?: boolean
    onClick: () => void
    type?: 'button' | 'submit'
}
const StandardButton: React.FC<StandardButtonProps> = ({ text, isLoading, enabled = true, fullWidth = false, type = 'button', onClick }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={!enabled || isLoading}
            className={
                'bg-loginAction text-black font-bold py-2 px-4 focus:outline-none focus:shadow-outline  w-full rounded-full ' +
                (fullWidth ? ' ' : 'md:w-40 ') +
                (enabled ? '' : 'opacity-50')
            }
        >
            <div className="flex flex-auto justify-center text-center">
                <div className={isLoading ? '' : 'w-0'}>
                    <Loading visible={isLoading} />
                </div>
                <span className="self-center">{isLoading ? '' : text}</span>
            </div>
        </button>
    )
}
export default StandardButton
