import React, { ReactNode, createContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useNav from '../hooks/NavigationHook'

const TitleSetContext = createContext<(title: string) => void>(() => {})
const InModal = createContext<boolean>(false)
const RequestClose = createContext<() => void>(() => {})

const TitleGetContext = createContext<string>('')

interface ModalContextProps {
    children: ReactNode
}

const ModalContext: React.FC<ModalContextProps> = ({ children }) => {
    const [title, setTitle] = useState('')
    const location = useLocation()
    const navigate = useNav()
    return (
        <RequestClose.Provider
            value={() => {
                navigate(location.pathname.split('/modal/')[0], { replace: true })
            }}
        >
            <InModal.Provider value={true}>
                <TitleSetContext.Provider value={setTitle}>
                    <TitleGetContext.Provider value={title}>{children}</TitleGetContext.Provider>
                </TitleSetContext.Provider>
            </InModal.Provider>
        </RequestClose.Provider>
    )
}

export { ModalContext, TitleSetContext, TitleGetContext, InModal, RequestClose }
