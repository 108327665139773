/* eslint-disable eqeqeq */
import { useContext } from 'react'
import { IsLoggedInProvider } from '../contexts/AppContext'
import AppRoute from '../resources/router/route'
import { AUTH_EMPLOYEE_ROUTES, PREAUTH_ROUTES, AUTH_ROUTES } from '../resources/routes-constants'
import { IsEmployeeProvider } from '../contexts/AuthContext'

export default function useDynamicRoutes(): AppRoute[] {
    let isEmployee = useContext(IsEmployeeProvider)
    let isLoggedIn = useContext(IsLoggedInProvider)

    if (!isLoggedIn) {
        return Object.values(PREAUTH_ROUTES)
    }

    return Object.values(AUTH_ROUTES).concat(Object.values(AUTH_EMPLOYEE_ROUTES).filter(() => isEmployee))
}
