import React, { createContext, ReactNode } from 'react'

import { Location, useLocation } from 'react-router-dom'

function getUnderLocation(location: Location<any>): Location<any> {
    const path = location.pathname
    const splitRoutes = path.split('/modal/')
    const isModal = splitRoutes.length > 1
    const pathBeforeModal = splitRoutes[0]
    const pathAferModal = isModal ? '/' + splitRoutes[1] : '/'

    let allState = { ...location.state }

    const underLocation: Location<any> = {
        pathname: pathBeforeModal,
        state: allState,
        hash: location.hash,
        key: location.key,
        search: ''
    }
    return underLocation
}

const GetOriginLocation = createContext<Location<any> | null>(null)
interface NavigationContextProps {
    children: ReactNode
}

const NavigationContext: React.FC<NavigationContextProps> = ({ children }) => {
    let location = useLocation()
    let underLocation = getUnderLocation(location)

    return <GetOriginLocation.Provider value={underLocation}>{children}</GetOriginLocation.Provider>
}

export { GetOriginLocation, NavigationContext }
