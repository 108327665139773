import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Toggle from '../../components/toggle/Toggle'
import InfoBubble from '../../components/infoBubble/InfoBubble'
import TeamMember from '../../models/TeamMember'
import { Plus } from '../../components/icons/Plus'
import { ChangeEvent, useContext, useEffect, useState } from 'react'
import { GetCustomerProvider, IsAdminProvider, LogOutProvider } from '../../contexts/AppContext'
import axios from 'axios'
import MoreButton from '../../components/moreButton/MoreButton'
import useCacheContext from '../../hooks/CacheHook'
import useLocalDataContext from '../../hooks/LocalSettingHook'
import useNav from '../../hooks/NavigationHook'
import { IsEmployeeProvider } from '../../contexts/AuthContext'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import PhoneNumberInput from '../../components/PhoneInput/PhoneInput'
import StandardButton from '../../components/button/StandardButton'

interface Settings {
    [key: string]: boolean
}

interface UserInfo {
    name: string
    email: string
    mobile_number: string
    notification_consent: boolean
}

type E164Number = string

function convertToE164Number(rawNumber: string): E164Number | string {
    // Remove non-digit characters from the input string
    const digitsOnly = rawNumber.replace(/\D/g, '')

    // Check if the resulting string is numeric and not empty
    if (/^\d+$/.test(digitsOnly)) {
        // Hardcode the country code for the US (+1)
        return `+1${digitsOnly}`
    }

    return ''
}

function formatPhoneNumber(e164Number: string): string {
    if (e164Number.length !== 12 || !e164Number.startsWith('+1')) {
        // Ensure the input is in the expected format
        throw new Error('Invalid E.164 formatted number ' + e164Number)
    }

    // Extract the area code, central office code, and line number
    const areaCode = e164Number.substr(2, 3)
    const centralOfficeCode = e164Number.substr(5, 3)
    const lineNumber = e164Number.substr(8, 4)

    // Format the phone number in a stylized way
    const stylizedNumber = `(${areaCode}) ${centralOfficeCode}-${lineNumber}`
    return stylizedNumber
}

interface CommunicationPreferences {
    text: boolean
    email: boolean
    phoneNumber: string
}

const CommunicationPreferencesPage: React.FC = () => {
    let [preferences, error, isLoading, reloadPrefs] = useCacheContext<CommunicationPreferences>(
        '/v1/user/settings/communication',
        (val) => {
            return val
        },
        null
    )
    let location = useLocation()
    var [emailOn, setEmailOn] = useState<boolean | null>(null)
    var [textOn, setTextOn] = useState<boolean | null>(null)
    useEffect(() => {
        reloadPrefs()
    }, [location])
    useEffect(() => {
        if (preferences != null) {
            setEmailOn(preferences.email)
            setTextOn(preferences.text)
        }
    }, [preferences])
    const navigate = useNav()
    return (
        <div>
            {/* Page content */}
            <div className="bg-gray-100 min-h-80v flex flex-col items-center">
                <InfoBubble title="Communication Preferences">
                    <div className="w-full flex flex-col items-start">
                        {emailOn != null && textOn != null && (
                            <div className="flex flex-col space-y-2 items-start p-5">
                                <p className="text-xl">Get Notified About Ready Board Updates Via:</p>
                                <div className="flex flex-row justify-between w-full">
                                    <p className="text-xl">Email:</p>
                                    <Toggle
                                        value={emailOn}
                                        setToggled={async (value) => {
                                            setEmailOn(value)
                                            await axios.post('/v1/user/settings/communication', {
                                                email: value
                                            })
                                            reloadPrefs()
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between w-full">
                                    <p className="pr-4 text-xl">Text message:</p>
                                    <Toggle
                                        value={textOn}
                                        setToggled={async (value) => {
                                            if (value && (preferences?.phoneNumber == null || preferences?.phoneNumber == '')) {
                                                navigate('modal/verifyPhoneNumber')
                                            } else {
                                                setTextOn(value)
                                                await axios.post('/v1/user/settings/communication', {
                                                    text: value
                                                })
                                                reloadPrefs()
                                            }
                                        }}
                                    />
                                </div>
                                {textOn && preferences?.phoneNumber != null && preferences?.phoneNumber != '' && (
                                    <>
                                        <p className="text-xl pt-5">Phone Number on File</p>
                                        <strong>{formatPhoneNumber(preferences?.phoneNumber)}</strong>
                                        <div className="flex flex-row">
                                            <button
                                                className="pr-4"
                                                onClick={() => {
                                                    navigate('modal/verifyPhoneNumber')
                                                }}
                                            >
                                                Change number
                                            </button>
                                            <button
                                                onClick={async () => {
                                                    await axios.post('/v1/user/settings/communication', {
                                                        phone_number: '',
                                                        text: false
                                                    })
                                                    reloadPrefs()
                                                }}
                                            >
                                                <p className="text-red-500">Remove number</p>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </InfoBubble>
            </div>
        </div>
    )
}

export default CommunicationPreferencesPage
