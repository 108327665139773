import React, { useEffect, useState } from 'react'
import useCacheContext from '../../../hooks/CacheHook'
import { ConditionalType, Field, HandleConditionalForOption, Option, isOptionArray, ImageData } from './Models/FormModels'
import YesNoComponent from './Form/YesNoComponent'
import MultiChoiceComponent from './Form/MultiChoiceComponent'
import DateComponent from './Form/DatePicker'
import { PhotoPickerComponent } from './Form/PhotoPickerComponent'
import { motion, AnimatePresence } from 'framer-motion'
import { PDFDownloadLink } from '@react-pdf/renderer'
import MyDocument from './Form/PreInspectionPDFDoc'
import { useParams } from 'react-router-dom'
import { Invoice } from '../../../models/Invoice'
import { useIndexedDB } from '../../../hooks/IndexDbHook'
import { useFormData } from '../../../hooks/FormDataHook'
import { ModalContext } from '../../../contexts/ModalContext'
import Modal from '../../../components/modal/Modal'
import InvoiceChooser from './AddressChooser'
import ModalCompatibleTitle from '../../../components/modal/ModalCompatibleTitle'
import AddressLabel from '../../../components/address/AddressLabel'
import StandardButton from '../../../components/button/StandardButton'
import TextFieldEntry from './Form/TextFieldEntry'

export interface PreInspectionFormData {
    fields: Field[]
}

type FormData = {
    [fieldId: string]: Option[] // Maps field ID to an array of option IDs
}

export type FormFieldResponse = {
    [fieldId: string]: Option[] | number | ImageData[] | Invoice | string | null // Maps field ID to an array of option IDs
}

type FormParams = {
    inspectionId: string
}

const PreInpectionForm: React.FC = () => {
    const { inspectionId } = useParams<keyof FormParams>() as FormParams

    let [preInspectionForm, error, isLoading] = useCacheContext<PreInspectionFormData>(
        '/v1/preinspectionForm',
        (val) => {
            return val
        },
        null,
        true,
        true
    )

    const [index, setIndex] = useState(-1)
    const [showAddressModal, setShowAddressModal] = useState(false)

    const { addFormData, getFormData, isLoadingDB } = useFormData()

    const [isFirstLoadComplete, setIsFirstLoadComplete] = useState(false)
    useEffect(() => {
        if (preInspectionForm) {
            setIsFirstLoadComplete(true)
        }
    }, [preInspectionForm])

    const initialFormData: FormFieldResponse = {
        // For each field, initialize an empty array or with default values if any
    }

    const [formData, setFormData] = useState<FormFieldResponse>(initialFormData)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        let updateForm = async () => {
            let form = await getFormData(inspectionId)
            if (form != undefined) {
                setFormData(form.data)
            }
        }
        if (!isLoadingDB) {
            updateForm()
        }
    }, [isLoadingDB])

    if (preInspectionForm == null) {
        return <h1>Loading</h1>
    }

    let currentInvoice = formData['form_invoice_id'] as Invoice | null

    return (
        <div className="bg-gray-100 h-full p-5">
            <div className="p-5 mb-5 bg-white rounded-lg">{headerDescription()}</div>
            <div className="p-9 mb-5 bg-white rounded-lg">
                <div className="font-semibold text-lg">What property are you inspecting?</div>
                <button
                    className="w-full bg-gray-100 px-4 py-2 rounded-md"
                    onClick={() => {
                        setShowAddressModal(true)
                    }}
                >
                    {currentInvoice == null ? 'Choose property name' : <AddressLabel address={currentInvoice.address} />}
                </button>
            </div>
            {preInspectionForm.fields.map((item) => {
                return (
                    <AnimatePresence>
                        {shouldDisplayField(item, formData, preInspectionForm) && (
                            <motion.div
                                key={item.id}
                                initial={isFirstLoadComplete ? { opacity: 0, height: 0 } : { opacity: 1, height: 'auto' }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.2 }}
                            >
                                <div className="p-5 mb-5 bg-white rounded-lg">
                                    {renderFormItem(item, formData, (data) => {
                                        addFormData({ id: inspectionId, status: 'draft', data: data })
                                        setFormData(data)
                                    })}
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                )
            })}
            <div className="p-5 mb-5 bg-white rounded-lg">{footerDescription()}</div>
            <StandardButton
                text="Submit"
                isLoading={isSubmitting}
                onClick={function (): void {
                    setIsSubmitting(true)
                    addFormData({ id: inspectionId, status: 'submitted', data: formData })
                }}
            />
            <ModalContext>
                <Modal
                    isOpen={showAddressModal}
                    title={'Property Name'}
                    onRequestClose={() => {
                        setShowAddressModal(false)
                    }}
                    sizeInfo="h-screen w-full md:h-80v md:w-9/12"
                >
                    <InvoiceChooser
                        didSelectInvoice={(invoice) => {
                            const updatedFormData = {
                                ...formData,
                                ['form_invoice_id']: invoice
                            }
                            addFormData({ id: inspectionId, status: 'draft', data: updatedFormData })

                            setFormData(updatedFormData)
                            setShowAddressModal(false)
                        }}
                    />
                </Modal>
            </ModalContext>
        </div>
    )
}

function footerDescription() {
    return (
        <div className="w-full">
            <p className="p-4">
                <strong>
                    A thorough pre-inspection aligns with our core values of quality relationships. By taking the time to assess the apartment's condition and
                    address any issues, we demonstrate our commitment to maintaining a high standard of quality in our work. This attention to detail fosters
                    trust and strengthens our relationships with both our teams and property managers, as they can rely on us to deliver exceptional results.{' '}
                </strong>
            </p>
        </div>
    )
}

function headerDescription() {
    return (
        <div className="w-full">
            <p className="p-4">
                <strong>Apartment Pre-Inspection Report</strong>
            </p>
            <img
                className="rounded-md p-4"
                src="https://s3.eu-central-1.amazonaws.com/onefid.content.assets/tqwaspqimjcqkqsa/web/978811/richtext/07b2e2bc71e34f36973c62ff0bbb74a9/pic/istockphoto_1224957858_612x612_jpeg_0c63a9a8-4230-462f-85ba-611d71023a00"
            />
            <p>To pre-inspect an apartment for cleaning, painting, and maintenance work, follow these steps:</p>
            <ol className="pl-6" style={{ listStyleType: 'decimal' }}>
                <li>
                    <strong>Start with a general overview:</strong> Walk through each room and make note of any visible issues or areas that require attention
                    for the services we are performing. This could include dirty walls, damaged surfaces for our painters, or any maintenance issues like leaky
                    faucets or broken fixtures for our team if they are performing a 99-point maintenance checklist.
                </li>
                <li>
                    <strong>Assess the cleanliness:</strong> Check the condition of the appliances, bathrooms, or general kitchen area. Look for excessively
                    dirty areas that will cause our teams to take more time, and document these extra charges with photos.
                </li>
                <li>
                    <strong>Inspect the walls:</strong> Examine the walls for any cracks, holes, or peeling paint. Note down areas that require patching or
                    repainting, and document with pictures.
                </li>
                <li>
                    <strong>Examine the kitchen:</strong> Inspect the appliances, countertops, cabinets, and sinks. Check for any signs of damage, wear and
                    tear, or cleanliness issues. Note down any repairs or cleaning needed.
                </li>
                <li>
                    <strong>Evaluate the bathroom(s):</strong> Inspect the condition of the toilet, sink, bathtub/shower, tiles, and any other fixtures. Look
                    for leaks, mold/mildew, or any other maintenance issues that need attention.
                </li>
                <li>
                    <strong>Review the electrical and plumbing systems:</strong> Test all light switches, outlets, and plumbing fixtures to ensure they are in
                    working order. Note down any issues that need attention if our team is performing a 99-point maintenance inspection.
                </li>
                <li>
                    <strong>Take photos:</strong> It's a good idea to take photos of any existing damage or issues as evidence before starting any work.
                </li>
            </ol>
        </div>
    )
}

function renderFormItem(item: Field, formData: FormFieldResponse, setFormData: React.Dispatch<React.SetStateAction<FormFieldResponse>>) {
    switch (item.type) {
        case 'description':
            return <h1>{item.prompt}</h1>
        case 'textfield':
            let text = (formData[item.id ?? ''] ?? []) as string

            return (
                <TextFieldEntry
                    field={item}
                    text={text}
                    onChange={(value) => {
                        const updatedFormData = {
                            ...formData,
                            [item.id ?? '']: value
                        }
                        setFormData(updatedFormData)
                    }}
                />
            )
        case 'yes_no':
            let optionArray = (formData[item.id ?? ''] ?? []) as Option[]
            let firstOption = optionArray.length > 0 ? optionArray[0] : null
            return (
                <YesNoComponent
                    key={item.id}
                    selectedOption={firstOption}
                    onChange={(value) => {
                        const updatedFormData = {
                            ...formData,
                            [item.id ?? '']: [value]
                        }
                        setFormData(updatedFormData)
                    }}
                    field={item}
                />
            )
        case 'date':
            let dateTimestamp = formData[item.id ?? ''] as number | null
            let firstDate: Date | null = null
            if (dateTimestamp != null) {
                const milliseconds = dateTimestamp * 1000
                // Create a new Date object using the milliseconds
                firstDate = new Date(milliseconds)
            }
            return (
                <DateComponent
                    field={item}
                    key={item.id}
                    onChange={(date) => {
                        if (date == null) {
                            const updatedFormData = {
                                ...formData,
                                [item.id ?? '']: null
                            }
                            setFormData(updatedFormData)
                        } else {
                            let value = Math.floor(date.getTime() / 1000)
                            const updatedFormData = {
                                ...formData,
                                [item.id ?? '']: value
                            }
                            setFormData(updatedFormData)
                        }
                    }}
                    selectedDate={firstDate}
                />
            )
        case 'multichoice':
            return (
                <MultiChoiceComponent
                    key={item.id}
                    selectedOptions={(formData[item.id ?? ''] ?? []) as Option[]}
                    onChange={(value) => {
                        const updatedFormData = {
                            ...formData,
                            [item.id ?? '']: value
                        }
                        setFormData(updatedFormData)
                    }}
                    field={item}
                />
            )
        case 'photo':
            let photoBlobs = (formData[item.id ?? ''] ?? []) as ImageData[]

            return (
                <PhotoPickerComponent
                    key={item.id}
                    field={item}
                    onPhotosSelected={(fileList) => {
                        if (fileList == null) {
                            return
                        }
                        const blobs = Array.from(fileList).map((file) => {
                            console.log(`File name: ${file.name}, File size: ${file.size} bytes`)

                            return {
                                blob: file,
                                width: 400,
                                height: 500
                            } as ImageData
                        })
                        const existingBlobs = (formData[item.id ?? ''] ?? []) as ImageData[]
                        const updatedFormData = {
                            ...formData,
                            [item.id ?? '']: blobs.concat(existingBlobs)
                        }
                        setFormData(updatedFormData)
                    }}
                    blobs={photoBlobs}
                />
            )
        default:
            return <h1>Unknown Field Type</h1>
    }
}

function shouldDisplayField(field: Field, formData: FormFieldResponse, formTemplate: PreInspectionFormData | null): Boolean {
    const conditional = field.showConditional

    if (conditional == null || conditional.id == null || conditional.id == '') {
        return true
    }

    const dataInQuestion = formData[conditional.id]

    const fieldInQuestion = formTemplate?.fields.find((field) => {
        return field.id == conditional.id
    })

    if (fieldInQuestion == null || dataInQuestion == null || !shouldDisplayField(fieldInQuestion, formData, formTemplate)) {
        return false
    }

    let shouldShow = true

    if (Array.isArray(dataInQuestion) && dataInQuestion.length == 0) {
        return false
    }

    if (isOptionArray(dataInQuestion)) {
        shouldShow = HandleConditionalForOption(dataInQuestion, conditional)
    }

    return shouldShow
}

export default PreInpectionForm
