import axios from 'axios'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
type ProjectDetailPageParams = {
    projectId: string
}

const NoteProblemPage: React.FC = () => {
    const { projectId } = useParams<keyof ProjectDetailPageParams>() as ProjectDetailPageParams

    const [selectedOption, setSelectedOption] = useState<string>('')
    const [customNote, setCustomNote] = useState<string>('')

    const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption(e.target.value)
    }

    const handleCustomNoteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCustomNote(e.target.value)
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        // Process form submission here. For example, you could send the data to an API.
        console.log({ selectedOption, customNote })
        alert(`Submitted with option: ${selectedOption}, custom note: ${customNote}`)
    }

    return (
        <div className="flex flex-col items-center justify-center p-4">
            <form onSubmit={handleSubmit} className="w-full max-w-md">
                <p className="text-lg font-semibold mb-4">Note a problem</p>
                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input type="radio" name="problem" value="bugs" onChange={handleOptionChange} className="form-radio" />
                        <span className="ml-2">Bugs</span>
                    </label>
                </div>
                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input type="radio" name="problem" value="can't enter" onChange={handleOptionChange} className="form-radio" />
                        <span className="ml-2">Can't enter</span>
                    </label>
                </div>
                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input type="radio" name="problem" value="no electricity" onChange={handleOptionChange} className="form-radio" />
                        <span className="ml-2">No electricity</span>
                    </label>
                </div>
                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input type="radio" name="problem" value="custom" onChange={handleOptionChange} className="form-radio" />
                        <span className="ml-2">Custom</span>
                    </label>
                    {selectedOption === 'custom' && (
                        <input
                            type="text"
                            value={customNote}
                            onChange={handleCustomNoteChange}
                            className="form-input mt-2 block w-full"
                            placeholder="Write your custom note here..."
                        />
                    )}
                </div>
                <button
                    type="button"
                    onClick={async () => {
                        const result = (
                            await axios.post('/v1/admin/invoice/noteProblem', {
                                message: 'Test',
                                SendEmail: false,
                                invoiceId: projectId
                            })
                        ).data
                    }}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
                >
                    Submit
                </button>
            </form>
        </div>
    )
}

export default NoteProblemPage
