import React, { useEffect, useState } from 'react'

interface DropdownProps {
    value: boolean
    setToggled: (toggled: boolean) => void
}

const Toggle: React.FC<DropdownProps> = ({ value, setToggled }) => {
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        setIsMounted(true)
    }, [])

    const toggleSwitch = () => {
        setToggled(!value)
    }

    const transitionClass = isMounted ? 'after:transition-all' : ''

    return (
        <div className="flex items-center justify-center">
            <label className="inline-flex relative items-center cursor-pointer">
                <input type="checkbox" className="sr-only peer" checked={value} onChange={toggleSwitch} />
                <div
                    className={`w-11 h-6 bg-gray-200 peer-checked:bg-navBg rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 ${transitionClass}`}
                ></div>
            </label>
        </div>
    )
}

export default Toggle
