import Dropdown from '../../components/dropdown/Dropdown'
import StandardButton from '../../components/button/StandardButton'
import { useContext, useState } from 'react'
import { RequestClose } from '../../contexts/ModalContext'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
import AddTeamMemberResponse from '../../api/AddTeamMember/AddTeamMemberResponse'
import axios from 'axios'
import { useCurrentCustomer } from '../../hooks/CurrentCustomerHook'

const AddEmployeeModal: React.FC = () => {
    let close = useContext(RequestClose)
    let [isLoading, setIsLoading] = useState(false)

    let [name, setName] = useState('')
    let [email, setEmail] = useState('')
    let [role, setRole] = useState('Member')
    let currentCustomerBeingViewed = useCurrentCustomer()

    return (
        <div className="bg-white h-full p-5">
            <ModalCompatibleTitle title="Invite New Employee" />
            <h1 className="text-10">{'You are inviting an employee to admin Magic Make Readies customer portal'}</h1>
            <div className="mb-4">
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Name"
                />
            </div>
            <div className="mb-4">
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    placeholder="Email"
                />
            </div>
            <div className="ml-0 flex flex-row items-center mb-6">
                <p className="mr-4">Member Role</p>
                <Dropdown
                    selectedOption={role}
                    options={['Admin', 'Member']}
                    onSelectionChange={(selection) => {
                        setRole(selection)
                    }}
                />
            </div>
            <div className="flex flex-row justify-between">
                <div />
                <StandardButton
                    text="Submit"
                    isLoading={isLoading}
                    onClick={async () => {
                        setIsLoading(true)
                        const result = (
                            await axios.post('/v1/employee/invite', {
                                email: email,
                                name: name,
                                role: role
                            })
                        ).data as AddTeamMemberResponse
                        if (result.success) {
                            close()
                            setIsLoading(false)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default AddEmployeeModal
