import { ReactNode, useContext } from 'react'
import { matchRoutes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ArrowLeft } from '../icons/ArrowLeft'
import { LogOutProvider } from '../../contexts/AppContext'
import { useNavigateOptions } from '../../hooks/NavigationHook'
import { useCurrentCustomer } from '../../hooks/CurrentCustomerHook'
import MoreButton from '../moreButton/MoreButton'
import { UserCircleIcon } from '../icons/UserCircle'
import TableRow from '../Row/TableRow'
import useDynamicRoutes from '../../hooks/useDynamicRoutes'
import { IsEmployeeProvider } from '../../contexts/AuthContext'

import magicImage from '../../magic.png'
interface HeaderComponentProps {
    children: ReactNode
}

const Header: React.FC<HeaderComponentProps> = ({ children }) => {
    let location = useLocation()
    let isEmployee = useContext(IsEmployeeProvider)
    let logout = useContext(LogOutProvider)
    let currentCustomerBeingViewed = useCurrentCustomer()

    const path = location.pathname
    const splitRoutes = path.split('/modal/')
    const isModal = splitRoutes.length > 1
    const pathBeforeModal = splitRoutes[0]
    const pathAferModal = isModal ? '/' + splitRoutes[1] : '/'
    const dynamicRoutes = useDynamicRoutes()
    let { navigateUser } = useNavigateOptions()

    let allState = { ...location.state }

    const underLocation = {
        pathname: pathBeforeModal,
        state: allState,
        hash: location.hash,
        key: location.key
    }

    const route = matchRoutes(
        Object.values(dynamicRoutes).map((val) => {
            return {
                path: val.route
            }
        }),
        underLocation
    )

    const routeInfo = dynamicRoutes.find((val) => {
        return route != null && val.route == route[0].route.path
    })
    let navigate = useNavigate()
    let headerVisible = false
    let headerTitle = ''
    let backVisible = false
    let backPath: string | null | undefined = null
    let currentCustomer = useCurrentCustomer()
    backVisible = routeInfo?.headerInfo?.handleBack != null

    if (routeInfo?.headerInfo != null) {
        headerTitle = routeInfo.headerInfo.customTitle?.(currentCustomerBeingViewed) ?? routeInfo.headerInfo.title
        headerVisible = true
    }

    if (route != null) {
        console.log(route[0].route.path)
    }

    if (!headerVisible) {
        return <div>{children}</div>
    }
    return (
        <div>
            <nav className="fixed w-full z-[1000] top-0 left-0 border-gray-600 bg-navBg h-20">
                <div className=" x-auto py-8 sm:px-6 lg:px-8 h-full flex items-center">
                    <div className="flex items-center justify-between h-full w-full">
                        <div className="flex items-center w-8 h-8 hover:opacity-75">
                            {backVisible && (
                                <button
                                    onClick={() => {
                                        if (routeInfo?.headerInfo?.handleBack !== undefined) {
                                            routeInfo?.headerInfo?.handleBack(navigateUser)
                                        }
                                    }}
                                >
                                    <ArrowLeft className="w-8 h-8 fill-white " />
                                </button>
                            )}
                        </div>
                        <div>
                            <div className="text-xl font-semibold text-white">{headerTitle}</div>
                        </div>
                        <div>
                            {
                                <MoreButton
                                    defaultHoveFunctionality={false}
                                    displayComponent={<UserCircleIcon className="w-10 h-10 fill-white hover:opacity-75" />}
                                >
                                    {isEmployee && (
                                        <>
                                            <TableRow
                                                onClick={() => {
                                                    navigate('/admin/home')
                                                }}
                                            >
                                                <p>Admin Home</p>
                                            </TableRow>
                                            <TableRow
                                                onClick={() => {
                                                    navigate('/admin/home/makeReadyBoard')
                                                }}
                                            >
                                                <p>All Make Ready Board</p>
                                            </TableRow>
                                        </>
                                    )}
                                    {currentCustomer != null && (
                                        <TableRow
                                            onClick={() => {
                                                navigate('/' + (currentCustomer?.id ?? '') + '/makeReady')
                                            }}
                                        >
                                            <p>Make Ready Board</p>
                                        </TableRow>
                                    )}
                                    {!isEmployee && (
                                        <TableRow
                                            onClick={() => {
                                                navigate('/' + (currentCustomer?.id ?? '') + '/settings/communicationPreferences')
                                            }}
                                        >
                                            <p>Preferences</p>
                                        </TableRow>
                                    )}
                                    <TableRow
                                        onClick={() => {
                                            logout()
                                        }}
                                    >
                                        <p className="text-red-500">
                                            <strong>Sign Out</strong>
                                        </p>
                                    </TableRow>
                                </MoreButton>
                            }
                        </div>
                    </div>
                </div>
            </nav>
            <div className="pt-20">{children}</div>
        </div>
    )
}

export default Header
