export type ErrorName = 'LOGGED_OUT' | 'SERVER_ERROR' | 'REFERENCE_ERROR' | 'UNKOWN_ERROR'
export class ErrorBase extends Error {
    name: ErrorName
    message: string
    cause: any

    constructor({ name, message, cause }: { name: ErrorName; message: string; cause: any }) {
        super()
        this.name = name
        this.message = message
        this.cause = cause
    }
}
