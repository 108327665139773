import LoggedOutComponent from '../LoggedOutComponent'
import { PREAUTH_ROUTES } from '../../resources/routes-constants'
import StandardButton from '../../components/button/StandardButton'
import { useContext, useRef, useState } from 'react'
import { LoginProvider } from '../../contexts/AppContext'
import useNav from '../../hooks/NavigationHook'
import Modal from '../../components/modal/Modal'
import ModalCompatibleTitle from '../../components/modal/ModalCompatibleTitle'
import { ModalContext } from '../../contexts/ModalContext'

function LoginPage() {
    let navigate = useNav()
    let login = useContext(LoginProvider)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loggingIn, setLoggingIn] = useState(false)
    const handleKeyPress = (event: any): void => {
        if (event.key === 'Enter') {
            if (password === '') {
                passwordInputRef.current?.focus()
            } else if (email === '') {
                usernameInputRef.current?.focus()
            } else {
                loginFunction()
            }
        }
    }

    const usernameInputRef = useRef<HTMLInputElement>(null)
    const passwordInputRef = useRef<HTMLInputElement>(null)

    const loginFunction = async () => {
        setErrorMessage('')
        setLoggingIn(true)
        let result = await login(email, password)
        if (result !== true) {
            setErrorMessage('invalid username or password')
        } else {
            // Auto navigation will handle this case
        }
        setLoggingIn(false)
    }

    return (
        <LoggedOutComponent>
            <div className="flex flex-col items-center justify-center mb-4">
                <h1 className="text-text text-4xl">Welcome</h1>
            </div>

            <div className="mb-4">
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update the state on user input
                    type="text"
                    placeholder="Email"
                    onKeyUp={handleKeyPress}
                    ref={usernameInputRef}
                />
            </div>
            <div className="mb-6">
                <input
                    ref={passwordInputRef}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // Update the state on user input
                    type="password"
                    placeholder="Password"
                    onKeyUp={handleKeyPress}
                />
            </div>

            <div>
                <ModalContext>
                    <Modal
                        isOpen={errorMessage !== ''}
                        title="Login Failed"
                        onRequestClose={() => {
                            setErrorMessage('')
                        }}
                        sizeInfo={'h-48 w-16 md:h-40v md:w-1/4'}
                        children={
                            <div className="flex p-12 items-center justify-center">
                                <ModalCompatibleTitle title="Login Failed" />
                                <div>
                                    <h1>
                                        Having trouble logging in? Try resetting your password <button onClick={() => {}}>here</button> or reach out to Magic
                                        Make Ready
                                    </h1>
                                </div>
                            </div>
                        }
                    />
                </ModalContext>
            </div>

            <div className="flex flex-col-reverse md:flex-row items-center justify-between">
                <button
                    className="text-link font-bold py-2 px-4"
                    type="button"
                    onClick={() => {
                        navigate(PREAUTH_ROUTES.FORGOT_ROUTE.route)
                    }}
                >
                    Forgot Password
                </button>
                <StandardButton
                    onClick={async () => {
                        loginFunction()
                    }}
                    text="Sign in"
                    isLoading={loggingIn}
                />
            </div>
        </LoggedOutComponent>
    )
}

export default LoginPage
