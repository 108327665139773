import { useNavigate, useParams } from 'react-router-dom'
import LoggedOutComponent from '../LoggedOutComponent'
import useNav from '../../hooks/NavigationHook'

type ResetPasswordPageParams = {
    resetId: string
}

function ResetPasswordPage() {
    let navigate = useNav()
    const { resetId } = useParams<keyof ResetPasswordPageParams>() as ResetPasswordPageParams

    return (
        <LoggedOutComponent>
            <form>
                <div className="flex flex-col items-center justify-center mb-4">
                    <h1 className="text-text text-2xl">Reset Password</h1>
                    <h1 className="text-text text-xl">Email: chris@nschris.com</h1>
                </div>

                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="password"
                        type="password"
                        placeholder="Password"
                    />
                </div>
                <div className="mb-4">
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="confirm-password"
                        type="password"
                        placeholder="Confirm Password"
                    />
                </div>
                <div className="flex flex-col-reverse md:flex-row items-center justify-between">
                    <button
                        className="text-link font-bold py-2 px-4"
                        type="button"
                        onClick={() => {
                            navigate('/')
                        }}
                    >
                        Back to login
                    </button>
                    <button
                        className="bg-loginAction text-black font-bold py-2 px-4 focus:outline-none focus:shadow-outline  w-full md:w-40 rounded-full"
                        type="button"
                    >
                        Reset Password
                    </button>
                </div>
            </form>
        </LoggedOutComponent>
    )
}

export default ResetPasswordPage
