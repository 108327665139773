import React, { useEffect, useMemo, useState } from 'react'
import useCacheContext from '../../../hooks/CacheHook'
import { ConditionalType, Field, HandleConditionalForOption, Option, isOptionArray, ImageData } from './Models/FormModels'
import YesNoComponent from './Form/YesNoComponent'
import MultiChoiceComponent from './Form/MultiChoiceComponent'
import DateComponent from './Form/DatePicker'
import { PhotoPickerComponent } from './Form/PhotoPickerComponent'
import { motion, AnimatePresence } from 'framer-motion'
import { PDFDownloadLink } from '@react-pdf/renderer'
import MyDocument from './Form/PreInspectionPDFDoc'
import { useParams } from 'react-router-dom'
import { Invoice } from '../../../models/Invoice'
import { useIndexedDB } from '../../../hooks/IndexDbHook'
import { useFormData } from '../../../hooks/FormDataHook'
import { ModalContext } from '../../../contexts/ModalContext'
import Modal from '../../../components/modal/Modal'
import AddressLabel from '../../../components/address/AddressLabel'
import TableRow from '../../../components/Row/TableRow'

export interface InvoiceChooserData {
    didSelectInvoice: (invoice: Invoice) => void
}

const InvoiceChooser: React.FC<InvoiceChooserData> = ({ didSelectInvoice }) => {
    let [invoices, error, isLoading] = useCacheContext<Invoice[]>('/v1/admin/invoice/all', (val) => {
        return val.invoices
    })

    const rows = useMemo(() => {
        invoices?.filter((item) => {
            return item.status == 'inProgress' || item.status == 'upcoming'
        })
    }, [invoices])

    if (isLoading) {
        return <h1>Loading</h1>
    }

    return (
        <div className="bg-gray-100 h-full p-5">
            {invoices?.map((item) => {
                return (
                    <TableRow
                        onClick={() => {
                            didSelectInvoice(item)
                        }}
                    >
                        <AddressLabel address={item.address} />
                    </TableRow>
                )
            })}
        </div>
    )
}

export default InvoiceChooser
