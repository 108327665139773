import React from 'react'

import './App.css'
import RootComponent from './RootComponent'
import axios from 'axios'
import GLOBAL_BASE_URL from './utility/baseURL'

axios.defaults.baseURL = GLOBAL_BASE_URL

function App() {
    const backgroundImageUrl = 'https://www.magicmakereadies.com/wp-content/uploads/2018/10/apartment_unit_turning.jpg' // Replace with your image URL

    return <RootComponent />
}

export default App
