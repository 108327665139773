// ExcelStyleTable.tsx
import React, { ReactNode } from 'react'
import { Cog } from '../icons/Cog'

interface ExcelStyleTableProps {
    columns: string[]
    customRender?: (columnKey: string, value: any) => ReactNode
    data: any[] // Replace with your data type
}

const ExcelStyleTable: React.FC<ExcelStyleTableProps> = ({ columns, data, customRender = null }) => {
    return (
        <div className="relative p-2">
            <table className="min-w-full min-h-full divide-y divide-gray-200" style={{ position: 'relative' }}>
                <thead className="bg-gray-50 ">
                    <tr className="">
                        {columns.map((column, index) => (
                            <th
                                key={index}
                                scope="col"
                                className="top-20 px-6 py-3 border text-center text-sm  text-gray-700 uppercase tracking-wider min-w-[200px] bg-gray-200 font-semibold"
                                style={{ width: `${100 / columns.length}%`, position: 'sticky' }}
                            >
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((column, colIndex) => (
                                <td key={colIndex} className="whitespace-nowrap border">
                                    {customRender ? (
                                        customRender(column, row[column])
                                    ) : (
                                        <div className="w-full px-6 py-4 ">
                                            <p>{row[column]}</p>
                                        </div>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ExcelStyleTable
