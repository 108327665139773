import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import useOnOutsideClick from '../../hooks/useOnOutsideClick'
import { useLocation } from 'react-router-dom'
import { TitleGetContext } from '../../contexts/ModalContext'
import { FocusOn } from 'react-focus-on'
import { use100vh } from 'react-div-100vh'
import { useBreakpoint } from '../../hooks/tailwind'
import useNav from '../../hooks/NavigationHook'
import goBackHook from '../../hooks/GoBackHook'

interface ModalProps {
    isOpen: boolean
    title: string | null
    onRequestClose: () => void
    sizeInfo: string
    children: ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, title, onRequestClose, sizeInfo, children }) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const onClose = () => {
        if (isOpen) {
            onRequestClose()
        }
    }
    const isMd = useBreakpoint('md')
    useOnOutsideClick([modalRef], onClose)

    const goBack = goBackHook()
    const location = useLocation()
    const contextTitle = useContext(TitleGetContext)

    let fullHeight = use100vh()
    if (fullHeight == null) {
        fullHeight = 200
    }

    const maxHeight = (isMd ? fullHeight * 0.8 - 45 : fullHeight - 48) + 'px'

    return (
        <div
            className={` fixed z-[500000] inset-0 top-0 flex md:backdrop-blur-sm md:items-center md:justify-center bg-opacity-50 transition-opacity ${
                isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
            } `}
        >
            <div
                className={sizeInfo + ` bg-white rounded shadow-md transform transition-transform ${isOpen ? 'translate-y-0' : 'translate-y-full'}`}
                style={{ overflow: 'hidden' }}
                onClick={() => {}}
                ref={modalRef}
            >
                {
                    // We don't want to turn on focus for smaller screens so that we can zoome correctly.
                }
                <FocusOn
                    enabled={isOpen}
                    onClickOutside={(e) => {
                        if (e instanceof MouseEvent) {
                            onClose()
                        }
                    }}
                    allowPinchZoom={true}
                    onEscapeKey={onClose}
                >
                    <div>
                        <div className="flex justify-between items-center bg-base-100 px-6 py-2">
                            {location.state?.previousRouteIsModal === true && (
                                <button
                                    onClick={() => {
                                        goBack()
                                    }}
                                >
                                    <img src={`/images/left-arrow.png`} width="20" alt="Back Arrow"></img>
                                </button>
                            )}
                            <h1 style={{ margin: '0 auto' }} className="text-2xl text-white">
                                {contextTitle ?? title ?? ''}
                            </h1>
                            <button onClick={onClose} className="w-8 h-8 flex items-center justify-center rounded-full">
                                <svg
                                    className="w-8 h-8 text-white"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path d="M6 18L18 6M6 6l12 12"></path>
                                </svg>
                            </button>
                        </div>

                        <div className={'overflow-y-auto w-full'} style={{ maxHeight: maxHeight, minHeight: maxHeight }}>
                            {/* <Div100vh> */}
                            <div className="h-full">{isOpen && children}</div>
                            {/* </Div100vh> */}
                        </div>
                    </div>
                </FocusOn>
            </div>
        </div>
    )
}

export default Modal
