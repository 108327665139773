import { useEffect, useState } from 'react'

import Fuse, { FuseOptionKey } from 'fuse.js'

interface FuzzySearchComponentProps<T> {
    data: T[]
    keys: FuseOptionKey<T>[]
    placeholder?: string
    didFilterItems: (items: T[]) => void
}

const FuzzySearchComponent = <T extends {}>({ data, keys, placeholder = 'Search...', didFilterItems }: FuzzySearchComponentProps<T>) => {
    const [query, setQuery] = useState('')

    useEffect(() => {
        const fuse = new Fuse<T>(data, { keys })
        if (query) {
            const results = fuse.search(query).map((result) => result.item)
            didFilterItems(results)
        } else {
            didFilterItems(data)
        }
    }, [query, data])

    return (
        <div className="w-full py-4">
            <input
                type="text"
                className="p-2 border border-gray-300 rounded-md shadow-sm w-full"
                placeholder={placeholder}
                onChange={(e) => setQuery(e.target.value)}
            />
        </div>
    )
}

export default FuzzySearchComponent
