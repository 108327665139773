import { useNavigate, useParams } from 'react-router-dom'
import JobStatus, { JobStatusColor } from '../models/JobStatus'
import Job from '../models/job'
import useCacheContext from '../hooks/CacheHook'
import { BoardItem, BoardItemColor, Invoice } from '../models/Invoice'
import PhotoGallery from '../components/gallery/PhotoGallery'
import Address from '../models/Address'
import LineItem from '../models/LineItem'
import { CalendarIcon } from '../components/icons/CalendarIcon'
import { SparklesIcon } from '../components/icons/SparklesIcon'
import { CheckCircleIcon } from '../components/icons/CheckCircle'
import Tooltip from '../components/tooltip/Tooltip'
import PhotoAlbum from 'react-photo-album'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow'
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails'
import Zoom from 'yet-another-react-lightbox/plugins/zoom'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import { useMemo, useState } from 'react'
import { useBreakpoint } from '../hooks/tailwind'
import ExcelStyleTable from '../components/classicTable/ClassicTable'
import MapKeys from '../components/mapKey/MapKey'
import { ConvertUnixToReadableDate } from '../utility/dates'
import Attachment from '../models/Attachment'

type ProjectDetailPageParams = {
    projectId: string
}

type AlphabetData = {
    description: string
    date: string
}

const ProjectDetailPage: React.FC = () => {
    const { projectId } = useParams<keyof ProjectDetailPageParams>() as ProjectDetailPageParams
    const [index, setIndex] = useState(-1)

    let [invoices, error, isLoading] = useCacheContext<Invoice[]>(
        '/v1/invoices',
        (val) => {
            return val.invoices
        },
        null,
        // Use the cache if there is a cache
        true,
        // Don't load because we are going to be pulling the data from the invoice directly
        false
    )

    let [directInvoice, directInvoiceError, isDirectInvoiceLoading] = useCacheContext<Invoice>(
        '/v1/invoice?invoiceId=' + projectId,
        (val) => {
            return val
        },
        null,
        // Use the cache if there is a cache
        true,
        // Don't load because we are going to be pulling the data from the invoice directly
        true
    )

    let [mappings, mapError, isLoadingMapping, reloadMappings] = useCacheContext<string[]>(
        '/v1/boardMappings',
        (val) => {
            return val.mappings
        },
        [],
        true,
        true
    )

    console.log(invoices)

    let invoice =
        directInvoice ??
        invoices?.find((invoice) => {
            return projectId == invoice.id
        })

    let columns = ['Date', 'Item']

    let rows = useMemo(() => {
        let rows: { [key: string]: BoardItem | null }[] = []

        mappings?.forEach((mapping) => {
            var row: { [key: string]: BoardItem | null } = {}

            let index = invoice?.boardItems?.findIndex((item) => {
                return item.name == mapping
            })

            let boardItems = invoice?.boardItems
            if (index != undefined && boardItems != null) {
                let boardItem = boardItems[index]
                row['Date'] = boardItem
                row['Item'] = boardItem
                rows.push(row)
            }
        })

        return rows
    }, [directInvoice, invoices, mappings])

    if (invoice == null) {
        return <h1 className="bg-gray-100 min-h-80v flex flex-col items-center"></h1>
    }

    let photos =
        invoice.attachments != null
            ? invoice.attachments.map((attachment: Attachment) => ({
                  src: attachment.url,
                  width: attachment.dimension.width,
                  height: attachment.dimension.height
              }))
            : []

    return (
        <div>
            {/* Page content */}
            <main className="bg-gray-100 min-h-80v flex flex-col items-center">
                <h1>{StatusText(invoice)}</h1>
                {/* Your page content goes here */}
                <p className="text-2xl text-center pb-10">
                    <strong>{invoice.address.street}</strong>
                    <br />
                    {getAddressLine2(invoice.address)}
                </p>
                {
                    <MapKeys
                        items={[
                            { name: 'Unscheduled', color: JobStatusColor(JobStatus.Upcoming, true) },
                            { name: 'Upcoming', color: JobStatusColor(JobStatus.Upcoming, false) },
                            { name: 'In Progress', color: JobStatusColor(JobStatus.InProgress, false) },
                            { name: 'Complete', color: JobStatusColor(JobStatus.Completed, false) }
                        ]}
                    />
                }
                <ExcelStyleTable
                    columns={columns}
                    data={rows}
                    customRender={(key, value) => {
                        if (value != null) {
                            let boardItem = value as BoardItem
                            if (key == 'Date') {
                                return (
                                    <div className={'w-full px-6 py-4 ' + BoardItemColor(boardItem)}>
                                        <p>{ConvertUnixToReadableDate(boardItem.date)}</p>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className={'w-full px-6 py-4 ' + BoardItemColor(boardItem)}>
                                        <p>{boardItem.name}</p>
                                    </div>
                                )
                            }
                        }
                        return <div></div>
                    }}
                />
                {/* <div className="flex flex-col w-full">
                    <h1 className="text-2xl pb-2">Line Items</h1>
                </div>
                <div className="w-4/5">
                    {invoice.lineItems.map((lineItem) => {
                        return (
                            <div className="flex flex-row w-full items-center justify-between">
                                <div className="flex flex-row align-baseline">
                                    <div className="flex flex-col">
                                        <div className="flex flex-row align-baseline">
                                            {getStatusIcon(lineItem)}
                                            <h1 className="pl-2">
                                                <strong>{lineItem.name}</strong>
                                            </h1>
                                        </div>
                                        <h1 className="pl-7">{lineItem.description}</h1>
                                    </div>
                                </div>
                                <div className="flex flex-row">
                                    <p className="pr-2">
                                        <strong>${lineItem.amount / 100.0}</strong>
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div> */}
                {directInvoice != null && invoice.attachments != null && invoice.attachments.length != 0 && (
                    <div className="w-full flex flex-col items-center">
                        <div className="mb-20" />
                        <p className="text-2xl">Photos</p>
                        <div className="w-full p-4">
                            <PhotoAlbum layout="masonry" photos={photos} onClick={({ index }) => setIndex(index)} />
                        </div>
                        <Lightbox
                            slides={photos}
                            open={index >= 0}
                            index={index}
                            close={() => setIndex(-1)}
                            // enable optional lightbox plugins
                            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
                        />
                    </div>
                )}
            </main>
        </div>
    )
}

function getStatusIcon(lineItem: LineItem) {
    if (lineItem.status == JobStatus.InProgress) {
        return (
            <Tooltip content="In progress">
                <SparklesIcon className='fill-black" w-5 pr-1' />
            </Tooltip>
        )
    } else if (lineItem.status == JobStatus.Upcoming) {
        return (
            <Tooltip content="Upcoming">
                <CalendarIcon className='fill-black" w-5 pr-1' />
            </Tooltip>
        )
    } else {
        return (
            <Tooltip content="Complete">
                <CheckCircleIcon className='fill-black" w-5 pr-1' />
            </Tooltip>
        )
    }
}

function getAddressLine2(address: Address): string {
    return `${address.city}, ${address.state}, ${address.zip}`
}

function StatusText(job: Invoice) {
    // Determine the status text
    let statusText
    switch (job.status) {
        case JobStatus.Completed:
            statusText = 'Completed'
            break
        case JobStatus.InProgress:
            statusText = 'In progress'
            break
        case JobStatus.Upcoming:
            statusText = 'Upcoming'
            break
        default:
            statusText = 'Unknown Status' // You can add a default case if needed
    }
    return statusText
}

export default ProjectDetailPage
