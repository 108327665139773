import { useContext } from 'react'
import { GetCustomerProvider } from '../contexts/AppContext'
import useCacheContext from './CacheHook'
import Customer from '../models/Customer'
import { useParams } from 'react-router-dom'
import useParamsHook from './GetParamsHook'
import { IsEmployeeProvider } from '../contexts/AuthContext'
import { CustomerListProvider } from '../contexts/CustomerContext'

export function useCurrentCustomer(): Customer | null {
    let isEmployee = useContext(IsEmployeeProvider)
    let customerid = useParamsHook()?.params['customerId'] as string
    let customer = useContext(GetCustomerProvider)
    console.log('IS Employee ' + isEmployee)
    let customers = useContext(CustomerListProvider)

    if (customer?.id === customerid) {
        return customer
    }

    return (
        customers?.find((customer) => {
            return customer.id === customerid
        }) ?? null
    )
}
