import { Route, Routes, useLocation, matchRoutes, useNavigate } from 'react-router-dom'
import Header from './components/header/Header'
import { ModalContext } from './contexts/ModalContext'
import Modal from './components/modal/Modal'
import LoginPage from './pages/login/LoginPage'
import useNav from './hooks/NavigationHook'
import AppRoute from './resources/router/route'
import useDynamicRoutes from './hooks/useDynamicRoutes'
import Footer from './components/footer/Footer'

const CustomerPortalRoutes = () => {
    const navigate = useNav()
    const location = useLocation()

    const modalNavigate = (routeName: string) => {
        const splitRoutes = path.split('/modal/')
        if (splitRoutes.length > 1) {
            navigate(splitRoutes[0] + '/modal' + routeName, {
                state: {
                    previousRouteIsModal: true
                }
            })
            return
        }

        navigate(routeName)
        return
    }

    const path = location.pathname
    const splitRoutes = path.split('/modal/')
    const isModal = splitRoutes.length > 1
    const pathBeforeModal = splitRoutes[0]
    const pathAferModal = isModal ? '/' + splitRoutes[1] : '/'

    let allState = { ...location.state }

    allState.modalFriendlyNavigate = modalNavigate

    const underLocation = {
        pathname: pathBeforeModal,
        state: allState,
        hash: location.hash,
        search: location.search,
        key: location.key
    }
    let dynamicRoutes = useDynamicRoutes()
    let overlocationProps = { ...allState }
    overlocationProps.inModal = true
    const overLocation = {
        pathname: pathAferModal,
        state: overlocationProps,
        hash: location.hash,
        search: location.search,
        key: location.key
    }
    return (
        <div>
            <Header>
                {RoutesDef(underLocation, dynamicRoutes)}
                <Footer />
            </Header>
            <div>
                <ModalContext>
                    <Modal
                        isOpen={isModal}
                        title={''}
                        onRequestClose={() => {
                            navigate(pathBeforeModal, {
                                state: {
                                    closedModal: true
                                }
                            })
                        }}
                        // size={{ height: '80vh', width: '80%' }}
                        sizeInfo="h-screen w-full md:h-80v md:w-9/12"
                    >
                        {RoutesDef(overLocation, dynamicRoutes)}
                    </Modal>
                </ModalContext>
            </div>
        </div>
    )
}

const RoutesDef = (location: any, routeList: AppRoute[]) => {
    const isLocal = process.env.REACT_APP_RUNNING_LOCAL ? true : false

    return (
        <Routes location={location}>
            {routeList.map((element) => {
                return <Route path={element.route} key={element.route} Component={element.component} />
            })}
        </Routes>
    )
}

export default CustomerPortalRoutes
