import React from 'react'
import { Field, Option } from '../Models/FormModels'

interface YesNoComponentProps {
    selectedOption: Option | null
    field: Field
    onChange: (value: Option) => void
}

const YesNoComponent: React.FC<YesNoComponentProps> = ({ selectedOption, field, onChange }) => {
    const { prompt, subprompt, options } = field

    const handleOptionChange = (value: Option) => {
        onChange(value)
    }

    const displayableOptions = options?.slice(0, 2) ?? []

    return (
        <div className="p-2">
            <div className="font-semibold text-lg">{prompt}</div>
            <div className="text-sm text-gray-600 mb-2">{subprompt}</div>
            <div className="flex rounded-full border border-gray-300 bg-white">
                {displayableOptions.map((option, index) => (
                    <>
                        <div
                            key={option.id}
                            className={`flex-1 text-center py-2 rounded-full cursor-pointer ${
                                selectedOption?.id === option.id ? 'bg-blue-500 text-white' : 'text-gray-700'
                            }`}
                            onClick={() => handleOptionChange(option)}
                        >
                            {option.value}
                        </div>
                        {index === 0 && selectedOption == null && <div className="border-r border-gray-300"></div>} {/* Separator */}
                    </>
                ))}
            </div>
        </div>
    )
}

export default YesNoComponent
