import { ReactNode } from 'react'
import { Plus } from '../icons/Plus'

interface KeyItem {
    name: String
    color: String
}

interface MapKeyProps {
    items: KeyItem[]
}
const MapKeys: React.FC<MapKeyProps> = ({ items }) => {
    return (
        <div className="flex flex-row">
            {items.map((item) => {
                return (
                    <div className="flex flex-row justify-center items-center p-4">
                        <div className={'w-4 h-4 mr-2 ' + item.color}></div>
                        <p>{item.name}</p>
                    </div>
                )
            })}
        </div>
    )
}

export default MapKeys
