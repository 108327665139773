import React, { useState, ReactNode, MouseEventHandler } from 'react'
import { EllipsisHorizontalIcon } from '../icons/EllipsisHorizontalIcon'
import { FocusOn } from 'react-focus-on'

type SidePanelProps = {
    sidePanel: ReactNode
    children: ReactNode
}

const SidePanelLayout: React.FC<SidePanelProps> = ({ sidePanel, children }) => {
    return (
        <div className="min-h-screen flex flex-col md:flex-row-reverse items-center md:items-start pb-6">
            <div className="w-full md:w-1/3 md:pr-3 flex flex-col items-center md:sticky md:top-20">{sidePanel}</div>
            <div className="w-full flex flex-col items-center">{children}</div>
        </div>
    )
}

export default SidePanelLayout
